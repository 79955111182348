import { message } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CREATE_SHAPE_FROM_PASTE,
  TEST_GAME,
} from "../redux/games/actions";
import { Shape } from "../pages/playtea-creator/create-game/canvas/game/classes/Shape";

export const useActionsGameState = ({
  gameState,
  gameReducer,
  requestedEndGame,
  setRequestedEndGame,
  defaultLabel,
  liveMode,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (!gameState.endScriptTerminated && requestedEndGame) {
      liveMode();
      setRequestedEndGame(false);
      message.info("Jogo finalizado com sucesso...");
    }
  }, [
    liveMode,
    gameState.endScriptTerminated,
    requestedEndGame,
    setRequestedEndGame,
  ]);

  useEffect(() => {
    if (gameReducer.endGame) {
      message.success(`${defaultLabel} finalizado, modo teste desativado`);
      liveMode();
      dispatch({
        type: TEST_GAME,
      });
    }
  }, [gameReducer, liveMode, dispatch, history, defaultLabel]);

  useEffect(() => {
    if (gameReducer.shapes?.length > 0) {
      gameReducer.shapes?.forEach((shape) => {
        const id = gameState.currentStage.getLastIdShape() + 1;
        const newShape = new Shape({
          ...shape,
          base64Image: shape.image64,
          id,
          matchId: [],
          image64: "",
        });
        gameState.currentStage.addShape([newShape]);
      });
      dispatch({
        type: CREATE_SHAPE_FROM_PASTE,
        payload: "[]",
      });
    }
  }, [gameReducer, gameState, dispatch]);
};
