import React from "react";
import { Col, Row } from "antd";
import { SCRIPT_NAMES } from "utils/enums";
import { arrayMove } from "react-sortable-hoc";
import { SorteableScriptList } from "./sorteable-script";

interface ScriptStepProps {
  game: any;
  scriptName: string;
  setScriptSelecteds: (data: any[]) => void;
  renderScript: any;
  gameState: any;
  keyScript: SCRIPT_NAMES;
  createRowsAction: any;
  selectedScript: any;
}

export const ScriptStep = ({
  game,
  keyScript,
  gameState,
  scriptName,
  renderScript,
  selectedScript,
  createRowsAction,
  setScriptSelecteds,
}: ScriptStepProps) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    gameState.currentStage[keyScript] = arrayMove(
      gameState.currentStage[keyScript],
      oldIndex,
      newIndex
    );

    game.updateCurrentStage(gameState.currentStage, gameState.stages);
  };

  return (
    <>
      <Col span={8} style={{ display: "flex" }}>
        <Row align="middle" justify="center">
          {scriptName}
        </Row>
      </Col>
      <Col span={12}>
        <SorteableScriptList
          useDragHandle
          renderScript={renderScript}
          selectedScript={selectedScript}
          setScriptSelecteds={setScriptSelecteds}
          keyScript={keyScript}
          onSortEnd={onSortEnd}
        />
      </Col>
      {createRowsAction(gameState.currentStage, keyScript)}
    </>
  );
};
