import React from "react";
import { BarsOutlined } from "@ant-design/icons";
import {
  SortableElement,
  SortableHandle,
  SortableContainer,
} from "react-sortable-hoc";
import "./styles.less";

const DragHandle = SortableHandle(() => (
  <BarsOutlined style={{ cursor: "pointer" }} />
));

const SortableItem = SortableElement(({ label, selected, value }) => (
  <li id={value} className={`sorteable-item ${selected ? "selected" : ""}`}>
    <DragHandle />
    {label}
  </li>
));

export const SorteableScriptList = SortableContainer(
  ({ keyScript, setScriptSelecteds, renderScript, selectedScript }) => {
    const scripts = renderScript(keyScript);

    return (
      <ul
        className="sorteable-list"
        onClick={(event: any) => {
          let scriptSelected: any[] = [];
          if (event.target.id) {
            scriptSelected.push({
              key: keyScript,
              script: event.target.id,
            });
          }

          setScriptSelecteds(scriptSelected);
        }}
      >
        {scripts?.map((script, index) => {
          return (
            <SortableItem
              key={`item-${script.value}`}
              index={index}
              value={script.value}
              label={script.label}
              selected={selectedScript?.script === script.value}
            />
          );
        })}
      </ul>
    );
  }
);
