import { combineReducers } from "redux";
import { encrypt } from "../../utils/crypto";

import { SAVE_LOCAL_STUDENT } from "./actions";

export const defaultPartnerState = {
  users: [],
};

export function localStudents(state = defaultPartnerState, action) {
  switch (action?.type) {
    case SAVE_LOCAL_STUDENT: {
      const { payload } = action;
      return {
        ...state,
        users: [
          ...(new Set([
            ...state.users,
            encrypt(JSON.stringify(payload)),
          ]) as any),
        ],
      };
    }

    default:
      return state;
  }
}

export const localStudentReducer = combineReducers({ localStudents });
