import React, { useState } from "react";
import { Modal } from "antd";
import { SorteableMenuStage } from "./sorteable-menu-stages";
import { arrayMove } from "react-sortable-hoc";

export const ModalStages = ({
  game,
  stages,
  visible,
  setVisible,
  changeStage,
}) => {
  const [selectedStage, setSelectedStage] = useState<Record<string, any>>();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      game.pauseStages();

      const newStages = arrayMove(stages, oldIndex, newIndex).map(
        (stage: any, index) => {
          stage.id = Number(index) + 1;

          if (index === 0) {
            stage.isCurrentStage = true;
          }
          return stage;
        }
      );

      game.pauseStages();
      game.updateStages({ stages: newStages, currentStage: newStages[0] });

      changeStage(newStages[0]?.id);
    }
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      okText="Ir para fase"
      okButtonProps={{
        disabled: !selectedStage,
      }}
      onOk={() => {
        changeStage(selectedStage?.id);
        setVisible(false);
        setSelectedStage(undefined);
      }}
      onCancel={() => setVisible(false)}
    >
      <SorteableMenuStage
        stages={stages}
        selectedStage={selectedStage}
        setSelectedStage={setSelectedStage}
        pressDelay={200}
        onSortEnd={onSortEnd}
        useWindowAsScrollContainer
        axis="y"
      />
    </Modal>
  );
};
