import React, { ReactNode } from "react";
import { classNames } from "react-extras";

interface Props {
  children: ReactNode;
  fluid?: boolean;
  width?: number;
}

export const Grid = ({ children, fluid, width }: Props) => {
  const classComp = classNames({
    "layout--row-center": !fluid,
  });

  return (
    <div className={classComp} style={{ maxWidth: width }}>
      {children}
    </div>
  );
};

Grid.defaultProps = {
  fluid: true,
  width: 832,
};
