import React, { CSSProperties, FC, ReactNode } from "react";
import { Layout as AntLayout } from "antd";
import { classNames } from "react-extras";
import { Footer } from "./footer";
import { Menu } from "../navigation/menu";
import { CONTEXTS } from "../../utils/enums";

interface Props {
  children: ReactNode;
  hasHeader?: boolean;
  isPlayTEA?: boolean;
  hideMenu?: boolean;
  theme?: "light" | "dark";
  style?: CSSProperties;
  styleFooter?: CSSProperties;
}

export const Layout: FC<Props> = ({
  children,
  hasHeader,
  isPlayTEA,
  hideMenu,
  theme,
  style,
}: Props) => {
  const classContent = classNames({
    "layout--content__dark": theme === "dark",
  });

  return (
    <AntLayout>
      <div className="layout--sticky">
        {hasHeader && (
          <Menu
            context={isPlayTEA ? CONTEXTS.PLAYTEA : CONTEXTS.PLAYTEA_CREATOR}
            theme="light"
            mode="horizontal"
            isPlayTEA={isPlayTEA}
            hideMenu={hideMenu}
          />
        )}

        <AntLayout.Content
          className={classContent}
          style={{ minHeight: 860, ...style }}
        >
          {children}
        </AntLayout.Content>
      </div>
      <Footer />
    </AntLayout>
  );
};

Layout.defaultProps = {
  hasHeader: true,
  theme: "light",
};
