import { Button, Col, Row } from "antd";
import React from "react";
import $ from "jquery";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { ChromePicker } from "react-color";
import "./styles.less";

const canvasOptions = {
  width: 850,
  height: 570,
};

export const updateSizeCanvasDesign = () => {
  const canvasDesign: any = document.getElementById("canvas-design");
  const colCanvas = document.querySelector(".col-canvas-design");
  if (canvasDesign) {
    canvasDesign.width = Number(colCanvas?.clientWidth) * 3;
    canvasDesign.height = Number(colCanvas?.clientHeight) + 100;
  }
};

const canvasD = () => {
  const canvas: any = document.getElementById("canvas-design");
  let context = canvas?.getContext?.("2d");
  let boundings = canvas?.getBoundingClientRect();

  // Specifications
  let mouseX = 0;
  let mouseY = 0;
  context.strokeStyle = "black"; // initial brush color
  context.lineWidth = 1; // initial brush width
  let isDrawing = false;

  // Mouse Down Event
  $(canvas).on("mousedown touchdown", function (event) {
    setMouseCoordinates(event);
    isDrawing = true;

    // Start Drawing
    context.beginPath();
    context.moveTo(mouseX, mouseY);
  });

  // Mouse Move Event
  $(canvas).on("mousemove touchmove", function (event) {
    setMouseCoordinates(event);
    if (isDrawing) {
      context.lineTo(mouseX, mouseY);
      context.stroke();
    }
  });

  // Mouse Up Event
  $(canvas).on("mouseup touchend", function (event) {
    setMouseCoordinates(event);
    isDrawing = false;
  });

  // Handle Mouse Coordinates
  function setMouseCoordinates(event) {
    mouseX = event.clientX - boundings.left;
    mouseY = event.clientY - boundings.top;
  }

  updateSizeCanvasDesign();

  return { context };
};

export const CanvasDesign = () => {
  const [color, setColor] = useState("black");
  const [brushes, setBrushes] = useState("1");
  const [context, setContext] = useState<any>();

  useEffect(() => {
    if (!context) {
      const { context } = canvasD();
      setContext(context);
    }
  }, [context]);

  useEffect(() => {
    if (context) context.lineWidth = Number(brushes);
  }, [brushes, context]);

  useEffect(() => {
    if (context) context.strokeStyle = color;
  }, [color, context]);

  const stylesBrushes = useMemo(() => {
    const styles = {
      backgroundColor: "#8cf0b9",
    };
    return {
      button1: brushes === "1" ? styles : {},
      button2: brushes === "2" ? styles : {},
      button3: brushes === "3" ? styles : {},
      button4: brushes === "4" ? styles : {},
      button5: brushes === "5" ? styles : {},
    };
  }, [brushes]);

  return (
    <Row align="middle" justify="center">
      <Col span={6} className="col-canvas-design">
        <Col
          className="chrome-design"
          style={{
            marginBottom: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ChromePicker
            color={color}
            onChange={(color: any) => setColor(color.hex)}
          />
        </Col>
        <div className="brushes">
          <button
            style={stylesBrushes.button1}
            type="button"
            value="1"
            onClick={() => setBrushes("1")}
          />
          <button
            style={stylesBrushes.button2}
            type="button"
            value="2"
            onClick={() => setBrushes("2")}
          />
          <button
            style={stylesBrushes.button3}
            type="button"
            value="3"
            onClick={() => setBrushes("3")}
          />
          <button
            style={stylesBrushes.button4}
            type="button"
            value="4"
            onClick={() => setBrushes("4")}
          />
          <button
            style={stylesBrushes.button5}
            type="button"
            value="5"
            onClick={() => setBrushes("5")}
          />
        </div>
        <Row justify="center">
          <Button
            onClick={() =>
              context.clearRect(0, 0, canvasOptions.width, canvasOptions.height)
            }
          >
            Limpar Desenho
          </Button>
        </Row>
      </Col>
      <Col span={18}>
        <canvas
          id="canvas-design"
          width={canvasOptions.width}
          height={canvasOptions.height}
          style={{ borderColor: "black" }}
        />
      </Col>
    </Row>
  );
};
