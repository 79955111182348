import moment from "antd/node_modules/moment";
import {
  CREATE_GAME,
  UPDATE_EYE_VISIBILITY,
  UPDATE_GAME,
  END_GAME,
  END_GAME_PLAY_TEA,
  TEST_GAME,
  RESET_GAME_STATE,
  SWIPE_LEFT_ACTION,
  SWIPE_RIGHT_ACTION,
  CLEAR_SWIPES,
  CREATE_SHAPE_FROM_PASTE,
} from "./actions";

export const defaultPartnerState = {};

export function game(state = defaultPartnerState, action) {
  switch (action?.type) {
    case CREATE_GAME: {
      return {
        ...state,
        isSaving: true,
      };
    }

    case END_GAME: {
      return {
        ...state,
        endGame: true,
      };
    }

    case END_GAME_PLAY_TEA: {
      const { payload: clock } = action;
      const diff = moment().diff(moment(clock), "seconds");
      let hasTimePlayed = Number(localStorage.getItem("HEA") || 0);
      hasTimePlayed += diff;
      localStorage.setItem("HEA", hasTimePlayed.toString());
      localStorage.setItem("AF", "1");
      window.location.href = "../playtea";
      return {
        ...state,
      };
    }

    case CREATE_SHAPE_FROM_PASTE: {
      const { payload } = action;
      return {
        ...state,
        shapes: JSON.parse(payload),
      };
    }

    case TEST_GAME: {
      return {
        ...state,
        endGame: false,
      };
    }

    case UPDATE_EYE_VISIBILITY:
      return {
        ...state,
        isSaving: true,
      };

    case UPDATE_GAME: {
      return {
        ...state,
        isSaving: true,
      };
    }

    case RESET_GAME_STATE: {
      return {
        ...defaultPartnerState,
        closedGame: false,
        isSaving: false,
        endGame: false,
      };
    }

    case SWIPE_LEFT_ACTION: {
      return {
        ...state,
        swipeLeft: true,
        swipeRight: false,
      };
    }

    case SWIPE_RIGHT_ACTION: {
      return {
        ...state,
        swipeLeft: false,
        swipeRight: true,
      };
    }

    case CLEAR_SWIPES: {
      return {
        ...state,
        swipeLeft: false,
        swipeRight: false,
      };
    }

    default:
      return state;
  }
}

export const gameReducer = game;
