import { CLEANUP_MECANICS_TYPE, STAGE_NAMES } from "utils/enums";
import { StagePairing } from "./Pairing";
import { CreateStageType } from "./create-stage.type";
import { StagePaiting } from "./Paiting";

export const getStageByMecanic = (
  mecanic: STAGE_NAMES,
  data: CreateStageType
) => {
  if (mecanic === STAGE_NAMES.PAINTING) {
    return new StagePaiting({
      ...data,
      paintingType: CLEANUP_MECANICS_TYPE.DELETING,
      colorPainting: "#ffffff",
    });
  }

  return new StagePairing({
    ...data,
  });
};
