import { axiosInstance } from "../common/app";
import { APP_API_ROOT } from "../common/constants";

class PupilsService {
  save(data: any, route: string) {
    let tutor: any = localStorage.getItem("tutor");
    let api = `${APP_API_ROOT}/aluno`;
    let method = "post";
    if (tutor) {
      tutor = JSON.parse(tutor);
      data.tutor = tutor.codigo;
    }

    if (route === "edit") {
      api = `${APP_API_ROOT}/aluno/${data.codigo}`;
      method = "put";
    }

    return axiosInstance[method](api, data);
  }

  async games(id: number, admin = true) {
    let route = `${APP_API_ROOT}/aluno/${id}/jogos`;

    if (admin) {
      route += "?admin=true";
    } else {
      route += "?disable=false";
    }
    return axiosInstance.get(route);
  }

  async delete(id: number) {
    return axiosInstance.delete(`${APP_API_ROOT}/aluno/${id}`);
  }

  async saveMetrics(id: number, metric_id: number, time: number) {
    return axiosInstance.put(`${APP_API_ROOT}/aluno/${id}/metricas`, {
      metric_id,
      time,
    });
  }
}

export default new PupilsService();
