import React, { CSSProperties } from "react";
import { Layout } from "antd";
import { isMobile } from "react-device-detect";

interface Props {
  style?: CSSProperties;
}
export const Footer = ({ style }: Props) => {
  return (
    <Layout.Footer
      style={{
        bottom: 0,
        width: "100%",
        position: isMobile || window.innerHeight < 900 ? "relative" : "fixed",
        height: 10,
      }}
    >
      <div className="common--text-center">
        <p className="layout-footer">
          PlayTEA & PlayTEA Creator, plataforma para criar e jogar jogos
          pedagógicos.
        </p>
      </div>
    </Layout.Footer>
  );
};
