import React, { useCallback, useEffect, useMemo } from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { Choose } from "react-extras";
import { useDispatch, useSelector } from "react-redux";
import { useIdentifyPlatform } from "./utils";
import LoginService from "../redux/management/login";
import { CLEAN_STUDENT_STATE } from "../redux/pupil/actions";
import {
  LOGOUT_MANAGEMENT,
  CLEAN_MANAGEMENT_STATE,
} from "../redux/management/actions";

export const PrivateRoute = ({ children, ...rest }: any) => {
  const {
    managementReducer: { management },
  }: any = useSelector((state) => state);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    studentReducer: { student },
  }: any = useSelector((state) => state);

  const data = useIdentifyPlatform?.();

  const verifySessionHistory = useCallback(async () => {
    try {
      await LoginService.verifySession();
    } catch {
      dispatch({
        type: LOGOUT_MANAGEMENT,
      });
      history.push(`${data?.link}/entrar`);
    }
  }, [dispatch, history, data]);

  useEffect(() => {
    if (Boolean(data?.withCreator) && Boolean(management?.codigo)) {
      verifySessionHistory();
    }
  }, [location, data, management, verifySessionHistory]);

  useMemo(async () => {
    if (Boolean(data?.withCreator) && student?.codigo) {
      dispatch({
        type: CLEAN_STUDENT_STATE,
      });
    }

    if (!data?.withCreator && management?.codigo) {
      dispatch({
        type: CLEAN_MANAGEMENT_STATE,
      });
    }
  }, [data, management, student, dispatch]);

  try {
    return (
      <Route
        {...rest}
        render={() => (
          <Choose>
            <Choose.When
              condition={
                Boolean(data?.withCreator) && Boolean(management?.codigo)
              }
            >
              {children}
            </Choose.When>

            <Choose.When
              condition={!data?.withCreator && Boolean(student?.codigo)}
            >
              {children}
            </Choose.When>

            <Choose.Otherwise>
              <Redirect
                to={{ pathname: `/${data?.link}/entrar`, state: { ...data } }}
              />
            </Choose.Otherwise>
          </Choose>
        )}
      />
    );
  } catch {
    return (
      <Route {...rest} render={() => <Redirect to={{ pathname: "/home" }} />} />
    );
  }
};
