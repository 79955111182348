import { LOGIN_MANAGEMENT, LOGOUT_MANAGEMENT } from "./actions";

export const loginAction = (payload) => {
  return {
    type: LOGIN_MANAGEMENT,
    payload,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT_MANAGEMENT,
  };
};
