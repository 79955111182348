import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
  useCallback,
} from "react";
import { Layout } from "../../../components/layout";
import { useHistory, Link } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Button,
  Breadcrumb,
  Modal,
  Tooltip,
  message,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import MentoringService from "../../../redux/management";
import PupilsService from "../../../redux/pupils";
import { LoaderBlock } from "../../../components/loader-block";
import { RegisterForm } from "../../../components/forms/form-register";
import moment from "moment";
import * as Yup from "yup";
import { bufferToBase64 } from "../../../utils/base64";
import { useDispatch, useSelector } from "react-redux";
import { Choose, If } from "react-extras";
import "./styles.less";
import { SAVE_LOCAL_STUDENT } from "../../../redux/pupil/actions";
import { useScreenOrientation } from "../../../hooks/useScreenOrientation";
import { ScreenOrientation } from "../../../enums";
import { CONTEXTS } from "../../../utils/enums";
import { decimalAdjust } from "../../../utils/decimal-ajust";

const validationSchema = Yup.object().shape({
  usuario: Yup.string()
    .min(6, "Digite um usuário válido")
    .required("Digite um e-mail válido"),
  senha: Yup.string()
    .min(6, "A senha deve conter mais de 6 caracteres")
    .required("Digite a senha de no mínimo 6 digitos"),
  cpassword: Yup.string()
    .oneOf([Yup.ref("senha"), null], "As senhas devem ser iguais")
    .required("Repita a senha"),
  nome: Yup.string().required("Digite o nome do aluno"),
  datanascimento: Yup.string().required("Digite a data de nascimento"),
  descricao: Yup.string(),
  foto: Yup.string(),
});

const initialValues = {
  nome: "",
  usuario: "",
  senha: "",
  cpassword: "",
  datanascimento: "",
  descricao: "",
  foto: "",
};

export const Pupils: FC = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pupils, setPupils] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [modal, setModalOpen] = useState(false);
  const [route, setRoute] = useState("create");
  const [pupilSelect, setPupilSelect] = useState(initialValues);
  const [buttonLabel, setButtonLabel] = useState("Cadastrar");
  const {
    managementReducer: { management },
  }: any = useSelector((state) => state);

  useScreenOrientation({ orientation: ScreenOrientation.PORTRAIT });

  const getPupils = useCallback(async () => {
    try {
      const response = await MentoringService.students(management?.codigo);
      let { statistics: statisticsResponse, metrics } = await statistics(
        management?.codigo,
      );
      const students = response?.data?.result?.map((student) => {
        const statistic = statisticsResponse?.reduce(
          (old, statistic) => {
            if (statistic.codigo === student.codigo) {
              if (statistic.disabled) {
                old.gamesDeveloping += statistic.games;
              } else {
                old.games += statistic.games;
              }
            }

            return old;
          },
          { games: 0, gamesDeveloping: 0 },
        );

        const helperType: any = {
          AF: "gamesFinished",
          HEA: "gamesRunning",
          ANF: "gamesNotRunning",
        };

        metrics?.forEach((metric) => {
          if (metric.codigo_aluno === student.codigo) {
            const keyToSave = helperType[metric.tipo];
            statistic[keyToSave] = decimalAdjust(Number(metric.valor), -2);
          }
        });

        return {
          ...student,
          metrics: {
            ...metrics,
            ...statistic,
          },
        };
      });
      setPupils(students);
    } catch (_) {
      message.error("Ops, não conseguimos recuperar informações dos alunos.");
    }

    setLoading(false);
  }, [management]);

  useEffect(() => {
    getPupils();
  }, [getPupils]);

  const savePupil = () => {
    setPupilSelect(initialValues);
    setRoute("create");
    setButtonLabel("Cadastrar");
    setModalOpen(true);
  };

  const statistics = async (codigo: any) => {
    try {
      const response = await MentoringService.studentsStatistics(codigo);
      return response?.data?.result;
    } catch (_) {
      message.warn(
        "Ops, não conseguimos recuperar informações sobre estatisticas dos alunos.",
      );

      return {};
    }
  };

  const onSubmit = async (values: any) => {
    try {
      values.datanascimento = moment(values.datanascimento).format(
        "YYYY-MM-DD",
      );
      await PupilsService.save(
        { ...values, tutor: management?.codigo, metrics: undefined },
        route,
      );
      Modal.success({
        title: "Sucesso",
        content:
          route === "edit"
            ? "Aluno atualizado com sucesso"
            : "Aluno salvo com sucesso.",
        onOk: async () => getPupils(),
      });
      setModalOpen(false);
    } catch {
      Modal.error({
        title: "Erro",
        content:
          route === "edit"
            ? "Erro ao tentar atualizar o aluno, tente novamente"
            : "Erro ao tentar salvar o aluno, tente novamente",
      });
    }
  };

  const button = (
    <Button
      style={{
        fontWeight: 700,
        fontSize: 16,
        height: "47px",
        marginRight: "20px",
      }}
      disabled={false}
      type="primary"
      size="large"
      className="common--submit-button"
      onClick={savePupil}
    >
      Novo Aluno
    </Button>
  );

  const editPupil = (pupil: any) => {
    pupil.cpassword = pupil.senha;
    setPupilSelect(pupil);
    setButtonLabel("Atualizar");
    setRoute("edit");
    setModalOpen(true);
  };

  const deletePupil = (pupil: any) => {
    Modal.info({
      title: "Deletar",
      content: `Tem certeza que deseja deletar o aluno ${pupil.nome}?`,
      cancelText: "Voltar",
      okText: "Excluir",
      okButtonProps: {
        className: "button-delete",
      },
      cancelButtonProps: {
        className: "common--secondary-submit-button",
      },
      okCancel: true,
      onOk: async () => {
        try {
          await PupilsService.delete(pupil.codigo);
          message.success(`Usuário ${pupil.nome} deletado com sucesso.`);
          setPupils((state: any) => {
            state = state.filter(
              (children: any) => children.codigo !== pupil.codigo,
            );
            return state;
          });
        } catch {
          message.error(
            `Não foi possivel excluir o aluno ${pupil.nome}, tente novamente`,
          );
        }
      },
    });
  };

  const gamesRunning = (pupil, minify?: boolean) => {
    let gamesRunning = pupil?.metrics?.gamesRunning;
    let parsedString = minify ? "/h" : "Horas";
    if (gamesRunning < 60) parsedString = `${minify ? "/s" : "Segundos"}`;
    else {
      const minutes = gamesRunning / 60;
      if (minutes < 60) {
        gamesRunning = minutes;
        parsedString = minify ? "/m" : "Minutos";
      } else {
        gamesRunning = gamesRunning / 60 / 3600;
      }
    }

    return `${decimalAdjust(gamesRunning || 0, -2)} ${parsedString}`;
  };

  return (
    <Layout hasHeader theme="light">
      <Breadcrumb style={{ display: "flex", justifyContent: "center" }}>
        <Breadcrumb.Item>
          <Link to={CONTEXTS.PLAYTEA_CREATOR}>Home</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>Alunos</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={24} justify="center" style={{ marginBottom: "40px" }}>
        {button}
      </Row>
      <Row
        gutter={24}
        justify="center"
        style={{ display: "flex", marginRight: "0px", marginLeft: "0px" }}
      >
        <Choose>
          <Choose.When condition={loading}>
            <LoaderBlock text="Buscando Alunos" spinning={loading} />
          </Choose.When>
          <Choose.When condition={Boolean(pupils.length) && !loading}>
            {pupils.map((pupil: any) => {
              const foto = pupil?.foto?.data
                ? atob(bufferToBase64(pupil.foto.data)).replace("./images", "")
                : pupil.foto;
              pupil.foto = foto;
              return (
                <Col key={pupil.codigo} style={{ minHeight: 500 }}>
                  <Card
                    key={pupil.codigo}
                    hoverable
                    loading={loading}
                    style={{
                      width: 300,
                      minHeight: 400,
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                    cover={
                      <img
                        alt="example"
                        src={pupil.foto}
                        style={{ maxHeight: 300, minHeight: 300 }}
                      />
                    }
                    actions={[
                      <Tooltip title={"Atividades disponíveis"}>
                        {pupil?.metrics?.games} <i className="fa fa-gamepad" />
                      </Tooltip>,
                      <Tooltip title={"Atividades desabilitados"}>
                        {pupil?.metrics?.gamesDeveloping}{" "}
                        <i className="fa fa-gamepad" />
                      </Tooltip>,
                      <Tooltip title={"Atividades completadas"}>
                        {pupil?.metrics?.gamesFinished}{" "}
                        <i className="fa fa-tachometer" />
                      </Tooltip>,
                      <Tooltip
                        title={`${gamesRunning(
                          pupil,
                        )} de atividades na plataforma`}
                      >
                        {gamesRunning(pupil, true)}{" "}
                        <i className="fa fa-clock-o" />
                      </Tooltip>,
                    ]}
                    onClick={() => {
                      dispatch({
                        type: SAVE_LOCAL_STUDENT,
                        payload: pupil,
                      });
                      history.push(
                        `${CONTEXTS.PLAYTEA_CREATOR}/alunos/${pupil.codigo}/jogos`,
                        { pupil },
                      );
                    }}
                  >
                    <Card.Meta
                      title={pupil.nome}
                      description={pupil.descricao}
                    />
                  </Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                      marginTop: "-15px",
                    }}
                  >
                    <Tooltip title={`Deletar aluno: ${pupil.nome}`}>
                      <DeleteOutlined
                        className="icon-trash"
                        onClick={() => deletePupil(pupil)}
                      />
                    </Tooltip>
                    <Tooltip title={`Editar aluno: ${pupil.nome}`}>
                      <EditOutlined
                        className="icon-edit"
                        onClick={() => editPupil(pupil)}
                      />
                    </Tooltip>
                  </div>
                </Col>
              );
            })}
          </Choose.When>
          <Choose.Otherwise>
            <div>
              {" "}
              Você não possui nenhum aluno, clique em novo aluno para cadastrar
            </div>
          </Choose.Otherwise>
        </Choose>
      </Row>
      <If condition={modal}>
        <Modal
          destroyOnClose
          visible={modal}
          cancelText="Fechar"
          footer={false}
          okType="primary"
          title="Novo Aluno"
          onCancel={() => setModalOpen(false)}
        >
          <RegisterForm
            initialValues={pupilSelect}
            buttonLabel={buttonLabel}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          />
        </Modal>
      </If>
    </Layout>
  );
};
