import React, { useState, useContext } from "react";
import { Modal, Select, Row, Input } from "antd";
import { If } from "react-extras";
import { GameContext } from "../..";
import $ from "jquery";
import { PAINEL_ACTIONS_SCRIPTS, SCRIPT_NAMES } from "utils/enums";
import { LIMIT_SHAPES } from "../game/classes/utils";

interface Props {
  script: string;
  visible: boolean;
  setVisible: any;
  assignScript: ({ actionStage, actionElement, action }) => void;
  noFig?: boolean;
}

export const ModalActions = ({
  script,
  visible,
  setVisible,
  assignScript,
  noFig = true,
}: Props) => {
  const { game } = useContext(GameContext);
  const [action, setAction] = useState(-1);
  const [actionStage, setActionStage] = useState("");
  const [actionElement, setActionElement] = useState<any>();
  const { gameState } = game;

  const checkShapeInUse = (shape) => {
    return (
      !gameState?.currentStage?.shapeInUse ||
      (gameState?.currentStage?.shapeInUse &&
        gameState?.currentStage?.shapeInUse.id !== shape.id &&
        shape.id !== LIMIT_SHAPES)
    );
  };

  return (
    <Modal
      okText="Adicionar"
      cancelText="Voltar"
      title={`Ações - ${script}`}
      visible={visible}
      onOk={() => {
        assignScript({ actionStage, actionElement, action });
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
        $("body").find("#add-script")?.remove();
      }}
    >
      <div>
        <Row align="middle" style={{ display: "grid" }}>
          <h5> Ações </h5>
          <Select
            onChange={(value: any) => (value !== -1 ? setAction(value) : "")}
          >
            <Select.Option value={-1}> </Select.Option>
            {noFig && (
              <Select.Option value={PAINEL_ACTIONS_SCRIPTS.NEW_IMAGE}>
                mostrar figura
              </Select.Option>
            )}
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.NEW_AUDIO}>
              tocar som
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.PREVIOUS_STAGE}>
              vai para fase anterior
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.NEXT_STAGE}>
              vai para próxima fase
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.SPECIFY_STAGE}>
              vai para fase desejada
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.END_GAME}>
              terminar jogo
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE}>
              mostrar elemento
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.HIDE_SHAPE}>
              esconder elemento
            </Select.Option>
            {[
              SCRIPT_NAMES.QUANDO_ERRAR,
              SCRIPT_NAMES.QUANDO_CLICAR,
              SCRIPT_NAMES.QUANDO_ACERTAR,
            ].includes(script as SCRIPT_NAMES) && (
              <Select.Option value={PAINEL_ACTIONS_SCRIPTS.DESTROY_SHAPE}>
                destruir elemento
              </Select.Option>
            )}
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.ACTIVE_SHAPE}>
              ativar elemento
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.INACTIVE_SHAPE}>
              inativar elemento
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.AWAITING_TIME}>
              aguardar tempo
            </Select.Option>
          </Select>
        </Row>
        <If condition={action === PAINEL_ACTIONS_SCRIPTS.SPECIFY_STAGE}>
          <Row style={{ display: "grid", marginTop: 12 }}>
            <h5> Fases </h5>
            <Select onChange={(value: any) => setActionStage(value)}>
              <Select.Option value="-1"> </Select.Option>
              {gameState?.stages?.map((stage) => (
                <Select.Option value={stage.id}>
                  Fase - {stage.name}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </If>
        <If
          condition={[
            PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE,
            PAINEL_ACTIONS_SCRIPTS.HIDE_SHAPE,
            PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE_STAGE,
            PAINEL_ACTIONS_SCRIPTS.ACTIVE_SHAPE,
            PAINEL_ACTIONS_SCRIPTS.INACTIVE_SHAPE,
          ].includes(action)}
        >
          <Row style={{ display: "grid", marginTop: 12 }}>
            <h5> Elementos </h5>
            <Select
              onChange={(value: any) => {
                let actionToSave = value;
                if (gameState?.currentStage?.shapeInUse && value === -1) {
                  actionToSave = gameState?.currentStage?.shapeInUse?.id;
                }

                setActionElement(actionToSave);
              }}
            >
              <Select.Option value={-1}>
                {" "}
                {gameState?.currentStage?.shapeInUse?.id
                  ? "Esse elemento"
                  : ""}{" "}
              </Select.Option>
              <Select.Option value={"todos"}>Todos Elementos</Select.Option>
              {gameState?.currentStage?.shapes
                ?.filter(
                  (shape) => shape.id !== LIMIT_SHAPES && checkShapeInUse(shape)
                )
                ?.sort((a, b) => a.id - b.id)
                .map((shape) => (
                  <Select.Option value={shape.id}>
                    Número da figura - {shape.id}
                  </Select.Option>
                ))}
            </Select>
          </Row>
        </If>
        <If condition={action === PAINEL_ACTIONS_SCRIPTS.AWAITING_TIME}>
          <Row style={{ display: "grid", marginTop: 12 }}>
            <h5> Tempo (em segundos) </h5>
            <Input
              type="number"
              onChange={(event: any) =>
                setActionElement(Number(event.target.value))
              }
            />
          </Row>
        </If>
      </div>
    </Modal>
  );
};
