import { LOGIN_STUDENT, LOGOUT_STUDENT } from "./actions";

export const loginAction = (payload) => {
  return {
    type: LOGIN_STUDENT,
    payload,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT_STUDENT,
  };
};
