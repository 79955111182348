import _ from "lodash";
import { getBase64 } from "../../../../../../components/forms/form-register/person-registry";
import { compressImage } from "../../utils/compress-image";
import { defaultValues, hexToRgb, LIMIT_SHAPES } from "./utils";
import { Gif } from "../../gif-animate";
import { playTEACreatorDimensions } from "./stages/RootStage";

export class Shape {
  public x: number;
  public y: number;
  public width: number;
  public height: number;
  public zIndex: number;
  public clickable: any;
  public visible: boolean;
  public velocity: number;
  public fill?: string;
  public id?: number;
  public matchId?: any[] = [];
  public base64Image?: any;
  public text?: any;
  public backGroundColor?: string;
  public opacity: number = 1;
  public primarySound?: any;
  public bordas?: boolean;
  public dificult?: number;
  public quandoAcertar?: any;
  public quandoClicar?: any;
  public quandoErrar?: any;
  public hidden?: boolean;
  public type?: string;
  public posInitialX: number = 0;
  public posInitialY: number = 0;
  public used: boolean = false;
  public velocityX: number = 0;
  public velocityY: number = 0;
  public image64: string;
  public sound: any;
  public states: any[];
  public icon?: string;
  public rotate?: any;
  public angle?: number;
  public cutImage?: any;
  public imageCl: any;
  public imageData?: any;
  public shadow: any;
  public hasShadow = false;
  public animated?: boolean;
  public animatedWidth?: boolean;
  public animatedHeight?: boolean;
  private directionX = 0;
  private directionY = 0;
  public isMoving = false;
  public active = true;
  public imageDataSize: number[][] = [];
  public dragoffx: number = 0;
  public dragoffy: number = 0;
  public painted?: boolean;
  public imageIgnoreBlanks = 0;
  public canPaint?: boolean;
  public keepBordersVisible?: boolean;
  public canShuffle?: boolean;
  public isMemoryShape?: boolean;
  public fileName = "";
  public canvasGif;
  private gifInterface?: Gif;
  private originalValuesSize;
  public filledPixels: number
  public percentagePaiting: number;

  constructor(shape?: any) {
    const {
      x,
      y,
      width,
      height,
      zIndex,
      clickable,
      visible,
      velocity,
      backGroundColor = defaultValues.backGroundColor,
      id,
      matchId,
      base64Image = "",
      text = { ...defaultValues.text },
      opacity = 1,
      primarySound = "",
      bordas = true,
      dificult = 0,
      quandoAcertar = [],
      quandoClicar = [],
      quandoErrar = [],
      hidden = false,
      states = [],
      type = defaultValues.type,
      icon = "",
      rotate = {},
      angle = 0,
      shadow = null,
      animated = false,
      animatedHeight = false,
      animatedWidth = false,
      canPaint = false,
      active = true,
      keepBordersVisible = false,
      canShuffle = false,
      isMemoryShape = false,
      fileName,
      originalValuesSize,
      percentagePaiting = 0.25,
    } = shape ?? {};

    this.dificult = dificult;
    this.type = type;
    this.primarySound = primarySound;
    this.opacity = opacity;
    this.animated = animated;
    this.animatedHeight = animatedHeight;
    this.animatedWidth = animatedWidth;
    this.zIndex = Number(zIndex);
    this.x = Number(x);
    this.y = Number(y);
    this.hidden = hidden;
    this.width = Number(width);
    this.height = Number(height);
    this.id = id;
    this.backGroundColor = backGroundColor;
    this.posInitialX = Number(x);
    this.posInitialY = Number(y);
    this.clickable = clickable;
    this.visible = Boolean(visible);
    this.used = false;
    this.velocity = Number(velocity);
    this.velocityX = 0;
    this.velocityY = 0;
    this.matchId = matchId;
    this.text = text;
    this.image64 = base64Image;
    this.bordas = bordas;
    this.quandoClicar = quandoClicar;
    this.quandoAcertar = quandoAcertar;
    this.quandoErrar = quandoErrar;
    this.states = states;
    this.icon = icon;
    this.rotate = rotate;
    this.angle = angle;
    this.imageCl = new Image();
    this.shadow = shadow;
    this.hasShadow = this.shadow !== null;
    this.isMoving = false;
    this.imageDataSize = [];
    this.imageIgnoreBlanks = 0;
    this.active = active;
    this.canPaint = canPaint;
    this.keepBordersVisible = keepBordersVisible;
    this.canShuffle = canShuffle;
    this.isMemoryShape = isMemoryShape;
    this.fileName = fileName;
    this.percentagePaiting = percentagePaiting;
    this.filledPixels = 0

    const { scaleToResize, scaleX, scaleY } = this.getFactorScale(
      playTEACreatorDimensions.height,
      playTEACreatorDimensions.width
    );

    this.originalValuesSize = originalValuesSize ?? {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      scaleToResize,
      scaleX,
      scaleY,
    };
  }

  async compressImageShape() {
    if (this.image64) {
      const file = await (await fetch(this.image64)).blob();
      this.image64 = await getBase64(await compressImage(file, 100));
    }
  }

  getFactorScale(newHeightCanvas: number, newWidthCanvas: number) {
    const scaleX = newWidthCanvas / playTEACreatorDimensions.width;
    const scaleY = newHeightCanvas / playTEACreatorDimensions.height;

    const scaleToResize = Math.min(scaleX, scaleY);

    return { scaleToResize, scaleX, scaleY };
  }

  //resize in mode tablet
  resize(
    newWidthCanvas: number,
    newHeightCanvas: number,
    forceResize: boolean
  ) {
    if (!this.originalValuesSize.resized || forceResize) {
      this.originalValuesSize = {
        width: this.width,
        height: this.height,
        x: this.x,
        y: this.y,
        resized: true,
      };
    }

    const { scaleToResize, scaleX, scaleY } = this.getFactorScale(
      newHeightCanvas,
      newWidthCanvas
    );

    this.originalValuesSize = {
      ...this.originalValuesSize,
      scaleToResize,
      scaleX,
      scaleY,
    };

    this.width = this.originalValuesSize.width * scaleToResize;
    this.height = this.originalValuesSize.height * scaleToResize;
    this.x = this.originalValuesSize.x * scaleX;
    this.y = this.originalValuesSize.y * scaleY;

    return this;
  }

  setData(data) {
    const dataClone: Shape[] = [];
    data.forEach(({ info, auxInfo, value }) => {
      dataClone.push(_.cloneDeep({ info, value: this[auxInfo ?? info] }));
      if (value) this[info] = value;
    });
    this.states.push(dataClone);
  }

  /**
   * Verifica o mouse está em cima do objeto
   * @param {Float} mouseX - posição mouse X
   * @param {Float} mouseY - posição mouse y
   */
  contains(mouseX: number, mouseY: number) {
    return (
      this.x <= mouseX &&
      this.x + this.width >= mouseX &&
      this.y <= mouseY &&
      this.y + this.height >= mouseY
    );
  }

  fillBorders({ ctx }) {
    ctx.strokeStyle = "#000";
    ctx.lineWidth = 2;
    ctx.setLineDash([0]);
  }

  applyImageData({ x, y, width, height, dAShadow, ctx }) {
    let screenData = ctx.getImageData(x, y, width, height);
    const hexToRgbResponse = hexToRgb(this.backGroundColor);
    for (let i = 0; i < screenData.data.length; i += 4) {
      const whiteCalc = 255 * 3;
      const whiteBlock =
        screenData.data[i] + screenData.data[i + 1] + screenData.data[i + 2];

      const isColorSpace = whiteBlock < whiteCalc;
      if (hexToRgbResponse?.total > 0 && isColorSpace) {
        screenData.data[i] = hexToRgbResponse.r;
        screenData.data[i + 1] = hexToRgbResponse.g;
        screenData.data[i + 2] = hexToRgbResponse.b;
      }

      const colors = {
        r: screenData.data[i],
        g: screenData.data[i + 1],
        b: screenData.data[i + 2],
        a: screenData.data[i + 3],
      };

      if (this.hasShadow) {
        screenData.data[i] -= dAShadow;
        screenData.data[i + 1] -= dAShadow;
        screenData.data[i + 2] -= dAShadow;
      }

      if (
        !hexToRgbResponse?.total &&
        !this.hasShadow &&
        colors.r + colors.g + colors.a > 0
      ) {
        screenData.data[i + 3] = this.opacity * 255;
      }
    }

    ctx.putImageData(screenData, x, y);
  }

  updateTempImageData(imageData) {
    if (!this.imageData) {
      this.imageData = imageData;
    }
  }

  fillImage({ ctx }) {
    this.imageCl.src = this.image64;

    const isGif = this.fileName?.includes(".gif");

    if (isGif && !this.canvasGif) {
      this.canvasGif = document.createElement("canvas");
      fetch(this.image64).then((r) => {
        let canvas = this.canvasGif;

        r.arrayBuffer().then((buffer) => {
          this.gifInterface = new Gif(buffer, canvas);
          this.gifInterface.frames(canvas, (ctx, frame) => {
            canvas.width = frame.width;
            canvas.height = frame.height;

            ctx.drawImage(frame.imageData, frame.x, frame.y);
          });
        });
      });
    }

    ctx.drawImage(
      isGif ? this.canvasGif : this.imageCl,
      this.x,
      this.y,
      this.width,
      this.height
    );
  }

  measureText(ctx) {
    ctx.fillStyle = this.text.fill;
    ctx.font = `${this.text.tam}pt Calibri`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    return ctx.measureText(this.text.value ?? "");
  }

  fillText({ ctx }) {
    this.measureText(ctx);

    ctx.fillText(
      this.text.value,
      this.x + this.width / 2,
      this.y + this.height / 2
    );
  }

  painters(ctx) {
    return {
      border: () => this.fillBorders({ ctx }),
      image: () => this.fillImage({ ctx }),
      text: () => this.fillText({ ctx }),
    };
  }

  renderRectangle(ctx, isLiveMode?: boolean) {
    ctx.fillRect(this.x, this.y, this.width, this.height);
    if (!this.hidden) {
      if (this.bordas) {
        this.painters(ctx).border();
        ctx.strokeRect(this.x, this.y, this.width, this.height);
      }

      if (this.image64) {
        this.painters(ctx).image();
      }

      if (this.text.value) {
        this.painters(ctx).text();
      }
    } else if (!isLiveMode || this.keepBordersVisible) {
      ctx.strokeStyle = "#000";
      ctx.setLineDash([10]);
      ctx.lineWidth = 3;
      ctx.strokeRect(this.x, this.y, this.width, this.height);
    }

    if (this.id !== LIMIT_SHAPES) {
      this.applyImageData({
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        dAShadow: this.shadow * 25.5,
        ctx,
      });
    }

    ctx.restore();
  }

  normalizeSize() {
    if (this.width < 0) {
      this.width = Math.abs(this.width);
      this.x -= this.width;
    }

    if (this.height < 0) {
      this.height = Math.abs(this.height);
      this.y -= this.height;
    }
  }

  draw(ctx, isLiveMode?: boolean) {
    ctx.save();
    if (this.visible) {
      if (!this.hidden) {
        if (!this.image64) {
          ctx.fillStyle = this.backGroundColor;
        } else {
          ctx.fillStyle = `rgba(255, 255, 255, 0.01)`;
        }
      } else {
        ctx.fillStyle =
          isLiveMode && !this.keepBordersVisible
            ? `rgba(255, 255, 255, 0)`
            : "#fff";
      }

      this.renderRectangle(ctx, isLiveMode);
    }

    if (!this.animated && this.velocityX !== 0) {
      this.updatePosition();
    }
  }

  animating(ctx, blocksCollision) {
    const halfVelocity = this.velocity === 5 ? 100 : this.velocity / 2;
    for (const blockCollision of blocksCollision) {
      this.directionY =
        this.y + this.height > blockCollision.height ? 1 : this.directionY;
      this.directionX =
        this.x + this.width > blockCollision.width ? 0 : this.directionX;

      if (this.animatedWidth) {
        if (!this.directionX) {
          if (this.x < 0) {
            this.x += halfVelocity;
            this.directionX = 1;
          } else {
            this.x -= halfVelocity;
          }
        } else {
          this.x += halfVelocity;
        }
      }

      if (this.animatedHeight) {
        if (this.directionY) {
          if (this.y > 0) {
            this.y -= halfVelocity;
          } else this.directionY = 0;
        } else {
          this.y += halfVelocity;
        }
      }
      this.draw(ctx, true);
    }
  }

  /**
   * Update de velocidade
   */
  updatePosition() {
    if (
      Math.abs(this.x - this.posInitialX) <= this.velocity &&
      Math.abs(this.y - this.posInitialY) <= this.velocity
    ) {
      this.velocityX = 0;
      this.velocityY = 0;
      this.isMoving = false;
      this.x = this.posInitialX;
      this.y = this.posInitialY;
    } else {
      this.x += this.velocityX;
      this.y += this.velocityY;
      this.isMoving = true;
    }
  }

  centerX() {
    return this.x + this.halfWidth();
  }

  centerY() {
    return this.y + this.halfHeight();
  }

  halfWidth() {
    return this.width / 2;
  }

  halfHeight() {
    return this.height / 2;
  }
}
