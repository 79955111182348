import React, { FC, ReactElement, useEffect } from "react";
import { Modal, Row } from "antd";
import { AppstoreOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { Layout } from "../../components/layout";
import { ContentBlock } from "../../components/layout/content-block";

import { CONTEXTS } from "../../utils/enums";
import { useDispatch, useSelector } from "react-redux";
import { REMOVE_FLAG_TEMPORALY_PASSWORD } from "../../redux/management/actions";
import "./styles.less";

export const PlayTEACreator: FC = (props): ReactElement => {
  const { managementReducer }: any = useSelector((state) => state);
  const { management } = managementReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    if (management?.temporalyPassword) {
      Modal.info({
        title: "Senha temporária",
        content: (
          <Row style={{ padding: "20px 20px 0px 20px" }}>
            <p>
              Você está acessando o sistema com uma senha temporária, lembre-se
              de alterá-la.
            </p>
            <p>
              Acesse o <b>perfil</b> e clique em <b>"Alterar Senha"</b>.
            </p>
          </Row>
        ),
        onOk: () => {
          dispatch({
            type: REMOVE_FLAG_TEMPORALY_PASSWORD,
            payload: { ...management, temporalyPassword: false },
          });
        },
      });
    }
  }, [management, dispatch]);

  return (
    <Layout {...props} theme="light" hideMenu>
      <Row justify="center" className="home-menu--container">
        <ContentBlock style={{ margin: 24 }}>
          <Link
            to={`${CONTEXTS.PLAYTEA_CREATOR}/alunos`}
            className="home-menu--card"
          >
            <UserOutlined className="menu-card-icon" />
            <div className="menu-card-title">Alunos</div>
          </Link>
        </ContentBlock>

        <ContentBlock style={{ margin: 24 }}>
          <Link
            to={`${CONTEXTS.PLAYTEA_CREATOR}/biblioteca-cenarios`}
            className="home-menu--card"
          >
            <AppstoreOutlined className="menu-card-icon" />
            <div className="menu-card-title">Cenários</div>
          </Link>
        </ContentBlock>
      </Row>
    </Layout>
  );
};
