export class Mouse {
  public x = 0;
  public y = 0;

  getPosition(event, canvas) {
    const scrollLeft = document.querySelector(".col-canvas")?.scrollLeft ?? 0;
    let mx = 0
    let my = 0
    let canvasOpt = canvas;

    if (canvasOpt) {
      if (canvasOpt.offsetParent !== undefined) {
        do {
          mx += canvasOpt.offsetLeft;
          my += canvasOpt.offsetTop;
        } while ((canvasOpt = canvasOpt.offsetParent));
      }
      this.x =
        (event.touches && event.touches.length
          ? event.touches[0].pageX
          : event.pageX) -
        mx +
        scrollLeft;
      this.y =
        (event.touches && event.touches.length
          ? event.touches[0].pageY
          : event.pageY) - my;
      return this;
    }

    return this
  }
}
