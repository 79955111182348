import { message } from "antd";
import _ from "lodash";
import { LIMIT_SHAPES } from "./canvas/game/classes/utils";

type DataSaveGame = {
  data: any;
  pupilCode: string;
  imageGame?: string;
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const dataSaveGame = ({ data, pupilCode, imageGame }: DataSaveGame) => {
  const formData = new FormData();
  const gameTemporary = _.cloneDeep(data);
  const { foto, name, ...game } = gameTemporary;
  if (game.stages?.length) {
    game.stages.map((stage) => {
      stage.shapes = stage.shapes.filter((shape) => shape.id !== LIMIT_SHAPES);
      return stage;
    });
    formData.append("game", JSON.stringify(game));
    formData.append("foto", imageGame ?? "#");
    formData.append("aluno", pupilCode);
    formData.append("nome", name);
  } else {
    return message.warn("Formato de arquivo invalido");
  }
  return { formData };
};

type DataSaveScene = {
  data: any;
  tutorCode: string;
  imageScene?: string;
};

export const dataSaveScene = ({
  data,
  tutorCode,
  imageScene,
}: DataSaveScene) => {
  const formData = new FormData();
  const gameTemporary = JSON.parse(JSON.stringify(data));
  const { foto, name, codigo, ...game } = gameTemporary;
  formData.append("game", JSON.stringify(game));
  formData.append("foto", imageScene ?? "#");
  formData.append("tutor", tutorCode);
  if (codigo) formData.append("codigo", codigo);
  formData.append("nome", name);
  return { formData };
};

export const introStepsInitialPage = [
  {
    intro:
      "Bem vindo a plataforma, este tutorial irá lhe ajudar a utilizar todos os recursos disponiveis.",
  },
  {
    element: "#step1",
    intro: "Salve o estado do seu jogo a qualquer momento.",
  },
  {
    element: "#step2",
    intro:
      "Exporte seu jogo, normalmente utilizado para compartilhar seus jogos antes de salva-lo.",
  },
  {
    element: "#step3",
    intro: "Adicione um novo elemento no tamanho padrão 100x100.",
    position: "left",
  },
  {
    element: "#step6",
    intro:
      "Inicia o modo onde você conseguirá testar as funcionalidades criadas.",
  },
  {
    element: "#step7",
    intro: "Volte para a página anterior.",
  },
  {
    element: "#step8",
    intro: "Lista os cenários do jogo.",
  },
  {
    element: "#step9",
    intro: "Crie novos cenários.",
  },
  {
    element: "#step12",
    intro: "Caso preciso rever os conceitos, basta clicar neste botão",
  },
];
