import React, { useEffect, useCallback } from "react";
import { GameContext } from "../..";
import { STAGE_NAMES } from "utils/enums";
import { getStageByMecanic } from "./classes/stages/get-stage-by-mecanic";

export const LoadCanvas = ({
  selectedGame,
  liveMode = false,
  isPlayTEA = false,
  children: Component,
  endGameOnFinish = false,
  mecanic,
  gameEngine,
}) => {
  const initGame = useCallback(
    (mecanic: STAGE_NAMES) => {
      if (!selectedGame?.game && !gameEngine?.gameState.stages?.length) {
        const stage = getStageByMecanic(mecanic, {
          canvasDoc: document.querySelector("canvas"),
          id: 1,
          mecanic,
        });
        stage.setName("Fase principal");
        gameEngine?.gameState.setImage(selectedGame?.foto);
        gameEngine?.gameState.setCodigo(selectedGame?.game?.codigo);
        gameEngine.updateStages({ stages: [stage] });
        gameEngine.updateCurrentStage(stage);
        gameEngine?.gameState.setEvents(stage);
        stage.resizeResolution();
        if (liveMode) {
          stage.setLiveMode(true, gameEngine.gameState);
          stage.start(true, gameEngine.gameState, true);
        }
        return;
      }

      if (!gameEngine?.gameState.stages?.length) {
        const stages = gameEngine.create(selectedGame, isPlayTEA);

        gameEngine.updateStages({ stages });
        gameEngine?.gameState.setImage(
          selectedGame.foto?.data
            ? Buffer.from(selectedGame.foto.data).toString("utf-8")
            : selectedGame.foto
        );
        gameEngine.updateCurrentStage(stages[0]);
        gameEngine?.gameState.setEvents(stages[0]);
        gameEngine?.gameState.setCodigo(selectedGame.codigo);
        gameEngine.gameState.setEndGame(endGameOnFinish);
        stages[0].resizeResolution();
        if (liveMode) {
          stages[0].setLiveMode(true, gameEngine.gameState);
          stages[0].start(true, gameEngine.gameState, true);
        }
      }
    },
    [selectedGame, gameEngine, endGameOnFinish, liveMode, isPlayTEA]
  );

  useEffect(() => {
    if (mecanic) {
      initGame(mecanic);
    }
  }, [initGame, mecanic]);

  useEffect(() => {
    if (
      gameEngine?.gameState?.currentStage?.id &&
      !gameEngine?.gameState.currentStage?.isCurrentStage
    ) {
      gameEngine?.gameState.currentStage.start(
        false,
        gameEngine?.gameState,
        isPlayTEA || gameEngine?.gameState.currentStage.liveMode
      );
    }
  }, [gameEngine, isPlayTEA]);

  return (
    <GameContext.Provider value={{ game: gameEngine }}>
      {Component}
    </GameContext.Provider>
  );
};
