import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Select,
  Input,
  Button,
  Upload,
  message,
  Popover,
  Tooltip,
  Checkbox,
} from "antd";
import { ChromePicker } from "react-color";
import Grid from "antd/lib/card/Grid";
import { steps } from "./steps";
import { GameContext } from "../..";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { IntroJS } from "../utils/tutorial";
import { ModalActions } from "./modal-actions";
import "./styles.less";
import { If } from "react-extras";
import { SCRIPT_NAMES, STAGE_NAMES } from "utils/enums";
import { SorteableScriptList } from "./sorteable-script";
import { defaultValues, LIMIT_SHAPES } from "../game/classes/utils";
import { assignScriptFigure } from "./utils";
import { arrayMoveImmutable } from "array-move";
import { compressImage } from "../utils/compress-image";

type GenerateRowType = {
  children: any;
  id?: string;
  show?: boolean;
  title: string;
  badgeExplaine?: string;
};

let limitKBGif = 500000;

export const generateRow = ({
  id,
  show = true,
  title,
  children,
  badgeExplaine,
}: GenerateRowType) => {
  return (
    show && (
      <Row id={id} className="rows-painel-attrs">
        <Col span={9}>
          <Row align="middle" justify="start">
            {title}
            <If condition={Boolean(badgeExplaine)}>
              <Popover content={badgeExplaine} trigger={"hover"}>
                <InfoCircleOutlined style={{ marginLeft: 6 }} />
              </Popover>
            </If>
          </Row>
        </Col>
        <Col span={13}>{children}</Col>
      </Row>
    )
  );
};

export const PainelAttrs = () => {
  const { game } = useContext(GameContext);
  const [initialSteps, setInitialSteps] = useState(
    !localStorage.getItem("steps-fig")
  );
  const [enableSteps, setEnabledSteps] = useState(false);
  const { gameState } = game;
  const { currentStage: stage } = gameState;
  const [shape, setShape] = useState(stage?.shapeInUse);
  const [visible, setVisible] = useState(false);
  const [script, setScript] = useState("");
  const [scriptSelecteds, setScriptSelecteds] = useState<any[]>([]);

  useEffect(() => {
    if (gameState.currentStage.shapeInUse?.id && initialSteps) {
      setEnabledSteps(true);
      setInitialSteps(false);
      localStorage.setItem("steps-fig", "true");
    }
  }, [gameState.currentStage.shapeInUse, initialSteps]);

  const renderScriptListToShape = (script: string) => {
    if (gameState.currentStage?.id) {
      return gameState.currentStage.shapeInUse[script]
        ?.sort((a, b) => a.order - b.order)
        .map((scriptAction) => {
          return {
            value: scriptAction.id,
            order: scriptAction.order,
            label: scriptAction.name,
          };
        });
    }
  };

  const createRowActions = ({
    title,
    step,
    stage,
    scriptName,
    show = true,
  }) => {
    const shape = stage?.shapeInUse;
    const scriptToSearch = scriptSelecteds.find(
      (scriptSelected) => scriptSelected.key === scriptName
    );

    const onSortEnd = ({ oldIndex, newIndex }) => {
      const newScripts = arrayMoveImmutable(
        shape[scriptName],
        oldIndex,
        newIndex
      );

      shape[scriptName] = newScripts.map((script: any, index) => ({
        ...script,
        order: index + 1,
      }));
    };

    return (
      show && (
        <Row id={step} style={{ marginTop: "10px" }}>
          <Col span={8} style={{ display: "flex" }}>
            <Row align="middle" justify="center">
              {title}
            </Row>
          </Col>
          <Col span={12}>
            <SorteableScriptList
              useDragHandle
              keyScript={scriptName}
              renderScript={renderScriptListToShape}
              selectedScript={scriptToSearch}
              onSortEnd={onSortEnd}
              setScriptSelecteds={setScriptSelecteds}
            />
          </Col>
          <Col span={4}>
            <Row>
              <Button
                size="small"
                className="add-script"
                onClick={() => {
                  setScript(scriptName);
                  setVisible(true);
                }}
              >
                <i className="fa fa-plus" aria-hidden="true" />
              </Button>
            </Row>
            <Row>
              <Button
                size="small"
                className="delete-script"
                disabled={!scriptToSearch}
                onClick={() => {
                  shape[scriptToSearch.key] = shape[scriptToSearch.key]
                    .filter((script) => script.id !== scriptToSearch.script)
                    .map((script, index) => ({ ...script, order: index + 1 }));

                  setScriptSelecteds([]);
                  game.updateCurrentStage(stage, gameState.stages);
                }}
              >
                <i className="fa fa-minus" aria-hidden="true" />
              </Button>
            </Row>
            {/* <Row>
            <Button
              size="small"
              className="view-script-detail"
            >
              <i className="fa fa-eye" aria-hidden="true" />
            </Button>
          </Row> */}
          </Col>
        </Row>
      )
    );
  };

  useEffect(() => {
    if (gameState.currentStage?.shapeInUse)
      setShape(gameState.currentStage?.shapeInUse);
  }, [gameState]);

  const onChangeProperty = (key, value, object = false, saveState = true) => {
    saveState && stage.shapeInUse.setData([{ info: key, value }]);
    if (object) object[key] = value;
    else stage.shapeInUse[key] = value;
    setShape((state) => {
      if (!object) state[key] = value;
      return { ...state };
    });
  };

  const createText = (event) => {
    const { value } = event.target;
    const { shapeInUse } = stage;
    shapeInUse?.setData([{ info: "text" }]);

    onChangeProperty("value", value, shape.text, false);

    const context = stage.getContext();

    context.fillStyle = shapeInUse.text.fill;
    context.font = `${shapeInUse.text.tam}pt Calibri`;
    context.textAlign = "center";
    const measureTextWidth = context.measureText(value).width;

    const margin = 10;
    const newWidth = measureTextWidth + 2 * margin;
    const newHeight = Number(shapeInUse.text.tam) + 2 * margin;

    // Redefinir o bloco para o tamanho mínimo
    const newX = shapeInUse.x * shapeInUse.originalValuesSize.scaleX;
    const newY = shapeInUse.y * shapeInUse.originalValuesSize.scaleY;

    onChangeProperty(
      "width",
      shapeInUse.width < newWidth ? newWidth : shapeInUse.width,
      false,
      false
    );
    onChangeProperty(
      "height",
      shapeInUse.height < newHeight ? newHeight : shapeInUse.height,
      false,
      false
    );
    onChangeProperty("x", newX, false, false);
    onChangeProperty("y", newY, false, false);
    stage.createCorners();
  };

  const updateSizeText = (event) => {
    const { value } = event.target;
    const context = stage.getContext();
    const { shapeInUse } = stage;
    shapeInUse.setData([{ info: "text" }]);
    onChangeProperty("tam", value, shape?.text, false);
    const newHeight = Number(value) + shape.height / 2;
    const height = value && newHeight > shape.height ? newHeight : shape.height;
    context.fillStyle = shapeInUse.text.fill;
    context.font = `${value}pt Calibri`;
    context.textBaseline = "middle";
    context.textAlign = "center";
    const measureText = stage.getContext().measureText(shape?.text?.value);
    const width =
      value && measureText.width > shape.width
        ? measureText.width
        : shape.width;
    onChangeProperty("width", width, false, false);
    onChangeProperty("height", height, false, false);

    stage.createCorners();
  };

  return (
    <Grid
      className="painelAttrs"
      style={{
        width: "100%",
        maxHeight: "720px",
        overflow: "scroll",
      }}
    >
      <Row
        align="middle"
        justify="center"
        style={{
          marginBottom: "15px",
          fontWeight: "bold",
        }}
      >
        Configurações do elemento
        <div
          id="step-fig31"
          className="tutorial-stage"
          onClick={() => setEnabledSteps(true)}
        >
          (Ver tutorial)
        </div>
      </Row>
      <IntroJS
        steps={steps}
        stepsEnabled={enableSteps}
        onExit={() => setEnabledSteps(false)}
      />
      {generateRow({
        id: "step-fig12",
        children: <Input disabled type="number" value={shape?.id} />,
        title: "Número do elemento",
      })}

      {generateRow({
        id: "step-fig26",
        children: (
          <Row align="middle" justify="center" style={{ display: "flex" }}>
            <Input
              style={{ width: "54%" }}
              value={shape?.text?.value}
              onChange={createText}
            />
            <Tooltip title="Tamanho do texto">
              <Input
                type="number"
                style={{ width: "29%", marginLeft: "5px" }}
                value={shape?.text?.tam}
                onChange={updateSizeText}
              />
            </Tooltip>
            <Popover
              content={
                <ChromePicker
                  color={shape?.text?.fill}
                  onChange={(color: any) => {
                    stage.shapeInUse.setData([{ info: "text" }]);
                    onChangeProperty("fill", color.hex, shape?.text, false);
                  }}
                />
              }
              trigger="click"
              title="Cor"
            >
              <Tooltip title="Cor do texto">
                <Input
                  size="large"
                  type="button"
                  style={{
                    backgroundColor: shape?.text?.fill,
                    width: "10%",
                    height: "30px",
                    marginLeft: "5px",
                    border: "1px solid black",
                  }}
                />
              </Tooltip>
            </Popover>
          </Row>
        ),
        title: "Texto",
      })}

      {generateRow({
        id: "step-fig27",
        children: (
          <Row>
            <Col span={12}>
              <Upload
                listType="picture-card"
                showUploadList={false}
                customRequest={() => ""}
                accept={`image/tiff, image/jpg, image/png, ${
                  stage.mecanic === STAGE_NAMES.PAIRING ? "image/gif" : ""
                }`}
                onChange={async (info) => {
                  try {
                    let oReader = new FileReader();

                    oReader.addEventListener("load", async (event) => {
                      onChangeProperty("image64", event.target?.result);
                      stage.shapeInUse.fileName = info.file?.name;
                    });

                    if (info.file.name?.includes(".gif")) {
                      if (info.file.size < limitKBGif) {
                        return oReader.readAsDataURL(
                          info.file.originFileObj as Blob
                        );
                      }

                      return message.warn(
                        `Não possível importar gif com mais de ${
                          limitKBGif / 1000
                        }kb`
                      );
                    } else {
                      const image = await compressImage(
                        info.file.originFileObj
                      );

                      oReader.readAsDataURL(image);
                    }
                  } catch {
                    message.error(
                      `Ocorreu um erro ai importar ${info.file.name}, tente novamente`
                    );
                  }
                }}
              >
                <div className="image-edit">
                  <PlusOutlined />
                  <div className="ant-upload-text">Imagem</div>
                </div>
              </Upload>
            </Col>
            {shape?.image64 && (
              <Col
                span={12}
                style={{
                  display: "grid",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "-21px",
                }}
              >
                <img src={shape?.image64} alt="shape" width="80" height="60" />
                <DeleteOutlined
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={() => {
                    onChangeProperty("image64", "");
                    stage.shapeInUse?.gifInterface?.clearFrames();
                    onChangeProperty("canvasGif", undefined);
                  }}
                />
              </Col>
            )}
          </Row>
        ),
        title: "Imagem",
      })}

      {generateRow({
        id: "step-fig19",
        children: (
          <ChromePicker
            color={shape?.backGroundColor}
            onChange={(color: any) => {
              onChangeProperty("backGroundColor", color.hex);
              if (
                ![
                  defaultValues.backGroundColorImage,
                  defaultValues.backGroundColor,
                ].includes(color.hex)
              ) {
                onChangeProperty("opacity", 1);
                onChangeProperty("hasShadow", false);
              }
            }}
          />
        ),
        title: "Cor",
      })}

      {generateRow({
        id: "step-fig17",
        show: stage.mecanic === STAGE_NAMES.PAINTING,
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.canPaint ? 1 : 0}
            onChange={(value: any) => onChangeProperty("canPaint", value === 1)}
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        title: "Pode pintar/limpar?",
      })}

      {generateRow({
        id: "step-fig50",
        show: stage.mecanic === STAGE_NAMES.PAINTING && shape?.canPaint,
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.percentagePaiting}
            onChange={(value: any) =>
              onChangeProperty("percentagePaiting", value)
            }
          >
            <Select.Option value={0.1}> 25% </Select.Option>
            <Select.Option value={0.25}> 50% </Select.Option>
            <Select.Option value={0.5}> 75% </Select.Option>
            <Select.Option value={1}> 100% </Select.Option>
          </Select>
        ),
        title: "% da área para pintar/limpar",
      })}

      {generateRow({
        id: "step-fig40",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.active ? 1 : 0}
            onChange={(value: any) => onChangeProperty("active", value === 1)}
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        title: "Ativo?",
      })}

      {generateRow({
        id: "step-fig13",
        children: (
          <Input
            type="number"
            value={shape?.zIndex}
            onChange={(event: any) =>
              onChangeProperty("zIndex", Number(event.target.value))
            }
          />
        ),
        title: "Profundidade",
      })}

      {generateRow({
        id: "step-fig18",
        children: (
          <Input
            type="range"
            min={1}
            disabled={
              shape?.hasShadow ||
              ![
                defaultValues.backGroundColorImage,
                defaultValues.backGroundColor,
              ].includes(shape?.backGroundColor)
            }
            max={10}
            value={Number(shape?.opacity * 10)}
            onChange={(event: any) => {
              onChangeProperty("opacity", Number(event.target.value) / 10);
            }}
          />
        ),
        title: "Opacidade",
      })}

      {generateRow({
        id: "step-fig30",
        children: (
          <Select
            style={{ width: "100%" }}
            disabled={
              ![
                defaultValues.backGroundColorImage,
                defaultValues.backGroundColor,
              ].includes(shape?.backGroundColor)
            }
            value={shape?.hasShadow ? 1 : 0}
            onChange={(value: any) => {
              const hasShadow = value === 1;
              onChangeProperty("hasShadow", hasShadow);

              if (hasShadow) {
                onChangeProperty("shadow", 10);
                onChangeProperty("opacity", 1);
              } else {
                onChangeProperty("shadow", null);
              }
            }}
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        title: "Sombreamento?",
      })}

      <If condition={shape.hasShadow}>
        {generateRow({
          id: "step-fig18",
          children: (
            <Input
              type="range"
              min={0}
              max={10}
              value={Number(shape?.shadow)}
              onChange={(event: any) => {
                onChangeProperty("shadow", Number(event.target.value));
              }}
            />
          ),
          title: "Tamanho do sombreamento",
        })}
      </If>

      {generateRow({
        id: "step-fig15",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.bordas ? 1 : 0}
            onChange={(value: any) => onChangeProperty("bordas", value === 1)}
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        title: "Bordas?",
      })}

      {generateRow({
        id: "step-fig16",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.clickable ? 1 : 0}
            onChange={(value: any) =>
              onChangeProperty("clickable", value === 1)
            }
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        badgeExplaine:
          "Habilitar/Desabilitar a funcionalidade de arrastar o elemento quando jogar.",
        title: "Arrastável?",
      })}

      {generateRow({
        id: "step-fig17",
        children: (
          <>
            <Select
              style={{ width: "100%" }}
              value={shape?.hidden ? 1 : 0}
              onChange={(value: any) => onChangeProperty("hidden", value === 1)}
            >
              <Select.Option value={1}> Sim </Select.Option>
              <Select.Option value={0}> Não </Select.Option>
            </Select>
            <If condition={shape?.hidden}>
              <Row align="middle" justify="center">
                <Checkbox
                  checked={shape?.keepBordersVisible}
                  onClick={() =>
                    onChangeProperty(
                      "keepBordersVisible",
                      !shape?.keepBordersVisible
                    )
                  }
                >
                  Manter bordas visiveis?
                </Checkbox>
              </Row>
            </If>
          </>
        ),
        badgeExplaine:
          "Esconde o elemento na tela. Você pode mostrar esse elemento usando uma ação de um script.",
        title: "Esconder?",
      })}

      {generateRow({
        id: "step-fig14",
        show: stage.mecanic === STAGE_NAMES.PAIRING,
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.velocity}
            onChange={(value: any) =>
              onChangeProperty("velocity", Number(value))
            }
          >
            <Select.Option value={10}> Lenta </Select.Option>
            <Select.Option value={30}> Média </Select.Option>
            <Select.Option value={50}> Alta </Select.Option>
            <Select.Option value={5}> Teletransporte </Select.Option>
          </Select>
        ),
        badgeExplaine:
          "É a velocidade que o elemento irá percorrer na tela quando for movimentado pelo aluno.",
        title: "Velocidade",
      })}

      {generateRow({
        id: "step-fig50",
        show: stage.mecanic === STAGE_NAMES.PAIRING,
        children: (
          <>
            <Select
              style={{ width: "100%" }}
              value={shape?.animated ? 1 : 0}
              onChange={(value: any) => {
                onChangeProperty("animated", value === 1);
                onChangeProperty("animatedHeight", value === 1);
                onChangeProperty("animatedWidth", value === 1);
              }}
            >
              <Select.Option value={1}> Sim </Select.Option>
              <Select.Option value={0}> Não </Select.Option>
            </Select>
            <If condition={shape?.animated}>
              <Row align="middle" justify="center">
                <Checkbox
                  checked={shape?.animatedHeight}
                  onClick={() =>
                    onChangeProperty("animatedHeight", !shape?.animatedHeight)
                  }
                >
                  Vertical
                </Checkbox>
                <Checkbox
                  checked={shape?.animatedWidth}
                  onClick={() =>
                    onChangeProperty("animatedWidth", !shape?.animatedWidth)
                  }
                >
                  Horizontal
                </Checkbox>
              </Row>
            </If>
          </>
        ),
        title: "Movimentar elemento?",
      })}

      {generateRow({
        id: "step-fig20",
        children: (
          <Input
            type="number"
            value={shape?.x}
            onChange={(event: any) => {
              onChangeProperty("x", Number(event.target.value));
              stage.createCorners();
            }}
          />
        ),
        title: "Posição Horizontal",
      })}

      {generateRow({
        id: "step-fig21",
        children: (
          <Input
            type="number"
            value={shape?.y}
            onChange={(event: any) => {
              onChangeProperty("y", Number(event.target.value));
              stage.createCorners();
            }}
          />
        ),
        title: "Posição Vertical",
      })}

      {generateRow({
        id: "step-fig22",
        children: (
          <Input
            type="number"
            value={shape?.width}
            onChange={(event: any) => {
              onChangeProperty("width", Number(event.target.value));
              stage.createCorners();
            }}
          />
        ),
        title: "Largura",
      })}

      {generateRow({
        id: "step-fig23",
        children: (
          <Input
            type="number"
            value={shape?.height}
            onChange={(event: any) => {
              onChangeProperty("height", Number(event.target.value));
              stage.createCorners();
            }}
          />
        ),
        title: "Altura",
      })}

      {generateRow({
        id: "step-fig24",
        show: stage.mecanic === STAGE_NAMES.PAIRING,
        children: (
          <Select
            value={shape?.matchId}
            mode="tags"
            tokenSeparators={[","]}
            style={{ width: "100%" }}
            onChange={(value: any) => onChangeProperty("matchId", value)}
          >
            {stage?.shapes
              ?.filter(
                (shp: any) =>
                  shp.id !== shape?.id &&
                  shp.id !== LIMIT_SHAPES &&
                  !shape?.matchId?.includes(shp.id)
              )
              .map((shp: any) => (
                <Select.Option key={shp.id} value={shp.id?.toString()}>
                  {" "}
                  {shp.id}
                </Select.Option>
              ))}
          </Select>
        ),
        badgeExplaine: `Onde é configurado quais elementos farão 'match' com a figura selecionada (irá aparecer o id de cada elemento)"`,
        title: "Elementos compatíveis",
      })}

      {generateRow({
        id: "step-fig25",
        show: stage.mecanic === STAGE_NAMES.PAIRING,
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.dificult}
            onChange={(value: any) => onChangeProperty("dificult", value)}
          >
            <Select.Option value={-1}> Sempre acertar </Select.Option>
            <Select.Option value={0}> Facilidade de acertar </Select.Option>
            <Select.Option value={1}>
              {" "}
              Média (metade do elemento sobreposto){" "}
            </Select.Option>
            <Select.Option value={2}>
              {" "}
              Alta (todo elemento sobreposto){" "}
            </Select.Option>
          </Select>
        ),
        badgeExplaine:
          "Dificuldade que o elemento terá para acertar o seu par quando acertar o alvo.",
        title: "Dificuldade de acerto",
      })}

      {createRowActions({
        show: stage.mecanic === STAGE_NAMES.PAIRING,
        title: "QuandoClicar?",
        step: "step28",
        scriptName: SCRIPT_NAMES.QUANDO_CLICAR,
        stage: gameState.currentStage,
      })}
      {createRowActions({
        title: "QuandoAcertar?",
        step: "step29",
        scriptName: SCRIPT_NAMES.QUANDO_ACERTAR,
        stage: gameState.currentStage,
      })}
      {createRowActions({
        show: stage.mecanic === STAGE_NAMES.PAIRING,
        title: "QuandoErrar?",
        step: "step30",
        scriptName: SCRIPT_NAMES.QUANDO_ERRAR,
        stage: gameState.currentStage,
      })}
      <ModalActions
        assignScript={(data) => {
          assignScriptFigure({ ...data, game, gameState, script, setVisible });
        }}
        script={script}
        setVisible={setVisible}
        visible={visible}
      />
    </Grid>
  );
};
