import {
  PAINEL_ACTIONS_SCRIPTS,
  PAINEL_ACTIONS_SCRIPTS_NAME,
} from "utils/enums";
import { compressImage } from "../utils/compress-image";
import { v4 as uuidV4 } from "uuid";
import { message } from "antd";
import $ from "jquery";

export const addSound = async (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        return resolve(this.result);
      });

      const newBlob = new Blob([file.slice(0, 500000)], { type: "audio/wav" });
      reader.readAsDataURL(newBlob);
    } catch (error) {
      return reject(error);
    }
  });
};

export const showFigure = async (input) => {
  const compress = await compressImage(input.files[0]);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) =>
      resolve(event.target.result)
    );
    reader.addEventListener("error", (event) => reject(event));
    reader.readAsDataURL(compress);
  });
};

export const assignScriptFigure = ({
  actionElement,
  actionStage,
  gameState,
  game,
  script,
  action,
  setVisible,
}) => {
  const {
    currentStage: { shapeInUse },
  } = gameState;
  const id = uuidV4();
  let errorMessage = "";
  const input = $("<input/>")
    .attr("type", "file")
    .attr("name", "file")
    .attr("id", "add-script");

  shapeInUse[script] = shapeInUse[script] ?? [];

  const order = shapeInUse[script]?.length + 1;
  let scriptToSave: Record<string, any> = {};
  switch (Number(action)) {
    case PAINEL_ACTIONS_SCRIPTS.NEW_IMAGE:
      input.attr("accept", "image/*");
      input.trigger("click");
      input.on("change focus", async function () {
        const self: any = this;
        if (self.files?.length) {
          const fig = await showFigure(self);
          scriptToSave = {
            type: PAINEL_ACTIONS_SCRIPTS_NAME.NEW_IMAGE,
            name: `Imagem ${self.files[0]?.name}`,
            value: fig,
          };
          game.updateCurrentStage(gameState.currentStage, gameState.stages);
        }
      });
      break;
    case PAINEL_ACTIONS_SCRIPTS.NEW_AUDIO:
      input.attr("accept", "audio/*");
      input.trigger("click");
      input.on("change", async function () {
        const self: any = this;
        if (self.files?.length) {
          const sound = await addSound(self.files[0]);
          scriptToSave = {
            type: PAINEL_ACTIONS_SCRIPTS_NAME.NEW_AUDIO,
            name: `Som - ${self.files[0].name}`,
            value: sound,
          };
          game.updateCurrentStage(gameState.currentStage, gameState.stages);
        }
      });
      break;
    case PAINEL_ACTIONS_SCRIPTS.PREVIOUS_STAGE:
      scriptToSave = {
        type: PAINEL_ACTIONS_SCRIPTS_NAME.PREVIOUS_STAGE,
        name: "Vai para fase anterior",
        value: true,
      };
      break;
    case PAINEL_ACTIONS_SCRIPTS.NEXT_STAGE:
      scriptToSave = {
        type: PAINEL_ACTIONS_SCRIPTS_NAME.NEXT_STAGE,
        name: "Vai para próxima fase",
        value: true,
      };
      break;
    case PAINEL_ACTIONS_SCRIPTS.SPECIFY_STAGE:
      if (actionStage) {
        scriptToSave = {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.SPECIFY_STAGE,
          name: `Vai para fase - ${actionStage}`,
          value: actionStage,
        };
      } else errorMessage = "Por favor, selecione uma fase";
      break;
    case PAINEL_ACTIONS_SCRIPTS.END_GAME:
      scriptToSave = {
        type: PAINEL_ACTIONS_SCRIPTS_NAME.END_GAME,
        name: "Terminar jogo",
        value: true,
      };
      break;
    case PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE:
      if (actionElement) {
        scriptToSave = {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE,
          name: `Mostrar figura - ${actionElement}`,
          value: actionElement,
        };
      } else errorMessage = "Por favor, selecione uma figura";
      break;
    case PAINEL_ACTIONS_SCRIPTS.HIDE_SHAPE:
      if (actionElement) {
        scriptToSave = {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.HIDE_SHAPE,
          name: `Esconder figura - ${actionElement}`,
          value: actionElement,
        };
      } else errorMessage = "Por favor, selecione uma figura";
      break;
    case PAINEL_ACTIONS_SCRIPTS.AWAITING_TIME:
      if (actionElement) {
        scriptToSave = {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.AWAITING_TIME,
          name: `Aguardar tempo - ${actionElement}`,
          value: actionElement,
        };
      } else errorMessage = "Por favor, insira um valor";
      break;

    case PAINEL_ACTIONS_SCRIPTS.DESTROY_SHAPE:
      if (shapeInUse) {
        scriptToSave = {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.DESTROY_SHAPE,
          name: `Destruir elemento`,
          value: shapeInUse.id,
        };
      }
      break;
    case PAINEL_ACTIONS_SCRIPTS.INACTIVE_SHAPE:
      scriptToSave = {
        type: PAINEL_ACTIONS_SCRIPTS_NAME.INACTIVE_SHAPE,
        name: `Inativar elemento - ${actionElement}`,
        value: actionElement,
      };
      break;

    case PAINEL_ACTIONS_SCRIPTS.ACTIVE_SHAPE:
      scriptToSave = {
        type: PAINEL_ACTIONS_SCRIPTS_NAME.ACTIVE_SHAPE,
        name: `Ativar elemento - ${actionElement}`,
        value: actionElement,
      };
      break;
    default:
      break;
  }

  if (errorMessage) {
    message.info(errorMessage);
  } else {
    scriptToSave.order = order;
    scriptToSave.id = id;

    shapeInUse[script].push(scriptToSave);
    game.updateCurrentStage(gameState.currentStage, gameState.stages);
    setVisible(false);
  }
};