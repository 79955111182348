import React, { FC, ReactElement } from "react";
import { Link } from "react-router-dom";

interface Props {
  hasLink?: boolean;
  height?: number;
  image: string;
}

export const Logo: FC<Props> = ({ hasLink, height, image }): ReactElement => {
  const ImagePartner = () => (
    <img alt="Logo" height={height} src={image || "/playtea.jpg"} />
  );

  if (hasLink) {
    return (
      <Link to="/">
        <ImagePartner />
      </Link>
    );
  }

  return <ImagePartner />;
};

Logo.defaultProps = {
  hasLink: true,
  height: 52,
};
