import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Form, Input, Row, Col, Modal } from "antd";
import { LoginOutlined, UserAddOutlined } from "@ant-design/icons";
import { object, string } from "yup";
import { ChangePasswordModal } from "../modals/change-password-modal";
import { RegisterForm } from "../forms/form-register";
import * as Yup from "yup";
import MentoringService from "redux/management";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { CONTEXTS } from "utils/enums";
import "./styles.less";
import { If } from "react-extras";

interface LoginFormProps {
  onFormSubmit: (loginValues: any, formik: any) => void;
  isSubmitting: boolean;
  plataform: any;
}

const validationSchema = object().shape({
  username: string()
    .min(6, "Entre com um usuário válido")
    .required("Digite um usuário válido"),
  password: string()
    .min(6, "A senha deve conter 6 digitos")
    .required("Digite a senha de no mínimo 6 digitos"),
});

const managementSelect = {
  nome: "",
  email: "",
  senha: "",
  datanascimento: "",
  cpassword: "",
  foto: "",
};

const validationSchemaRegister = Yup.object().shape({
  email: Yup.string()
    .email("Digite um e-mail válido")
    .required("Digite um e-mail válido"),
  senha: Yup.string()
    .min(6, "A senha deve conter mais de 6 caracteres")
    .required("Digite a senha de no mínimo 6 digitos"),
  cpassword: Yup.string()
    .oneOf([Yup.ref("senha"), null], "As senhas devem ser iguais")
    .required("Repita a senha"),
  nome: Yup.string().required("Digite o nome do aluno"),
  datanascimento: Yup.string().required("Insira uma data válida"),
  foto: Yup.string().nullable(),
});

export const LoginForm = ({
  onFormSubmit,
  isSubmitting,
  plataform,
}: LoginFormProps) => {
  const [hasForgotPassModal, toggleForgotPassModal] = useState(false);
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const history = useHistory();
  const onSubmit = async (values: any) => {
    if (plataform.withCreator) {
      try {
        values.datanascimento = moment(values.datanascimento).format(
          "YYYY-MM-DD"
        );
        const {
          data: { result },
        } = await MentoringService.save(values);
        if (result?.insertId) values.codigo = result.insertId;
        Modal.success({
          title: "Sucesso",
          content: "Usuário criado com sucesso",
          onOk: () => history.push(CONTEXTS.PLAYTEA_CREATOR),
        });
      } catch (err) {
        Modal.error({
          title: "Erro",
          content: "Erro ao criar o cadastro, tente novamente",
        });
      }
    }
  };

  const forgotPasswordDispatch = async ({ email }: { email: string }) => {
    try {
      await MentoringService.forgotPassword(email);
      Modal.success({
        title: "Sucesso",
        onOk: () => toggleForgotPassModal(false),
        okText: "Entendi",
        okCancel: false,
        content:
          "Uma senha temporária foi enviado ao seu e-mail, verifique sua caixa de entrada",
      });
    } catch (err) {
      Modal.error({
        title: "Erro",
        content:
          "Houve um problema ao tentar recuperar sua senha, tente novamente mais tarde",
      });
    }
  };

  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ errors, handleChange, handleSubmit, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-label">Usuário</div>

          <Form.Item
            hasFeedback
            className="common--margin-bottom20"
            validateStatus={
              touched.username && errors.username ? "error" : undefined
            }
            help={touched.username && errors.username}
          >
            <Input
              className="form-input"
              name="username"
              disabled={isSubmitting}
              value={values.username}
              onChange={handleChange("username")}
              tabIndex={1}
              size="large"
            />
          </Form.Item>

          <div className="form-label">Senha</div>

          <Form.Item
            hasFeedback
            validateStatus={
              touched.password && errors.password ? "error" : undefined
            }
            help={touched.password && errors.password}
          >
            <Input.Password
              className="form-input"
              name="password"
              disabled={isSubmitting}
              value={values.password}
              onChange={handleChange("password")}
              tabIndex={2}
              size="large"
            />
          </Form.Item>

          <Row gutter={24} justify="space-around" align="middle">
            <If condition={plataform.withCreator}>
              <Col xs={24} sm={12} lg={12} style={{ width: "80%" }}>
                <Button
                  icon={<UserAddOutlined />}
                  loading={isSubmitting}
                  onClick={() => setOpenModalRegister(true)}
                  type="primary"
                  size="large"
                  style={{ width: "100%" }}
                  className="common--secondary-submit-button"
                >
                  Cadastrar
                </Button>
              </Col>
            </If>
            <Col
              xs={24}
              sm={12}
              lg={12}
              className="entry-button"
              style={{ width: "80%" }}
            >
              <Button
                htmlType="submit"
                icon={<LoginOutlined />}
                loading={isSubmitting}
                type="primary"
                size="large"
                style={{ width: "100%" }}
                className="common--submit-button"
              >
                Entrar
              </Button>
            </Col>
          </Row>
          <Row justify="end" align="middle" style={{ marginTop: 10 }}>
            <If condition={plataform.withCreator}>
              <Col className="link" onClick={() => toggleForgotPassModal(true)}>
                Esqueceu sua senha?
              </Col>
            </If>
          </Row>

          <ChangePasswordModal
            visible={hasForgotPassModal}
            toggleForgotPassModal={toggleForgotPassModal}
            onFormSubmit={(values) => forgotPasswordDispatch(values)}
          />

          <Modal
            visible={openModalRegister}
            onCancel={() => setOpenModalRegister(false)}
            destroyOnClose={true}
            cancelText={"Fechar"}
            width={800}
            footer={false}
            okType={"primary"}
            title={"Cadastrar-se"}
          >
            <RegisterForm
              management
              onSubmit={onSubmit}
              initialValues={managementSelect}
              validationSchema={validationSchemaRegister}
              buttonLabel={"Cadastrar-se"}
            />
          </Modal>
        </form>
      )}
    </Formik>
  );
};
