import React from "react";
import { Card, Modal, Row } from "antd";

export const ModalActivities = ({
  modalActivities,
  setModalActivities,
  setVisibleActivity,
  activities,
}) => {
  return (
    <Modal
      visible={modalActivities}
      title="Selecione um tipo de atividade"
      onCancel={() => setModalActivities(false)}
      okText="Gerar atividade"
      cancelButtonProps={{
        className: 'common--secondary-submit-button'
      }}
      bodyStyle={{
        alignItems: "center",
        justifyContent: "center",
        display: "grid",
        textAlign: "center",
      }}
    >
      <Row justify="center" align="middle">
        {activities.map(({ title, src, onClick }) => (
          <Card
            hoverable
            onClick={(event) => {
              setVisibleActivity(true);
              onClick(event);
            }}
            style={{
              display: "grid",
              maxWidth: 210,
              maxHeight: 240,
              margin: 12,
            }}
          >
            <Row justify="center" align="middle">
              <img
                alt="menu-card"
                width={150}
                height={150}
                src={src}
                className="menu-card-icon"
              />
              <div className="menu-card-title">{title}</div>
            </Row>
          </Card>
        ))}
      </Row>
    </Modal>
  );
};
