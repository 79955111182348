import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { END_GAME, END_GAME_PLAY_TEA } from "redux/games/actions";
import {
  loadAudio,
  sleep,
} from "../pages/playtea-creator/create-game/canvas/game/classes/utils";
import { PAINEL_ACTIONS_SCRIPTS_NAME } from "utils/enums";

export const usePlayScriptTerminarShapes = ({
  endGame,
  gameState,
  isPlayTEA,
  nextStage,
  setGameState,
}) => {
  const dispatch = useDispatch();

  useMemo(async () => {
    let value = -1;
    const { currentStage } = gameState;

    const _startStage = async (stage: any) => {
      currentStage.stopRenderCanvas();
      currentStage.pauseScene();
      currentStage.acertosExecutados = 0;
      nextStage(stage)();
    };

    if (gameState.scriptTerminated) {
      setGameState((state) => ({
        ...state,
        scriptTerminated: false,
        endScriptTerminated: true,
      }));

      let scriptsToRun = currentStage.quandoTerminar.sort(
        (a, b) => a.order - b.order
      );

      for (let i = 0; i < scriptsToRun.length; i++) {
        const quandoTerminarAction = scriptsToRun[i];
        let shapeToAction = currentStage.shapes.find(
          (shape) => shape.id === quandoTerminarAction.value
        );
        switch (quandoTerminarAction.type) {
          case PAINEL_ACTIONS_SCRIPTS_NAME.NEW_IMAGE:
            shapeToAction.hidden = false;
            shapeToAction.image64 = quandoTerminarAction.value;
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.NEW_AUDIO:
            await loadAudio(quandoTerminarAction, false, !isPlayTEA);
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.END_GAME:
            value = -1;
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.NEXT_STAGE:
            const nextIndex = gameState.stages.findIndex(
              (value) => value.id === currentStage.id
            );
            const nextStage = gameState.stages?.[nextIndex + 1];
            value = nextStage?.id;
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.PREVIOUS_STAGE:
            const prevIndex = gameState.stages.findIndex(
              (value) => value.id === currentStage.id
            );
            const prevStage = gameState.stages?.[prevIndex - 1];
            value = prevStage?.id;
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.SPECIFY_STAGE:
            value = quandoTerminarAction.value;
            break;

          case PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE:
            if (shapeToAction) {
              shapeToAction.hidden = false;

              if (scriptsToRun[i].value === "todos") {
                currentStage.shapes.forEach((shape) => {
                  shape.hidden = false;
                });
              } else {
                shapeToAction.hidden = false;
              }
            }
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE_STAGE:
            if (shapeToAction) {
              shapeToAction.hidden = false;

              if (scriptsToRun[i].value === "todos") {
                currentStage.shapes.forEach((shape) => {
                  shape.hidden = false;
                });
              } else {
                shapeToAction.hidden = false;
              }
            }
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.HIDE_SHAPE:
            if (shapeToAction) {

              if (scriptsToRun[i].value === "todos") {
                currentStage.shapes.forEach((shape) => {
                  shape.hidden = true;
                  shape.keepBordersVisible = true;
                });
              } else {
                shapeToAction.hidden = true;
              }
            }
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.AWAITING_TIME:
            await sleep(quandoTerminarAction.value * 1000);
            break;
          default:
            break;
        }

        if (shapeToAction?.id) {
          shapeToAction.draw(currentStage.getContext(), true);
        }
      }

      let stage = gameState.stages.find((stage) => stage.id === value);

      if (stage) {
        await _startStage(stage);
      } else {
        dispatch({
          type: endGame ? END_GAME_PLAY_TEA : END_GAME,
          payload: currentStage.clock,
        });
      }

      setGameState((state) => ({
        ...state,
        endScriptTerminated: false,
      }));
    }
  }, [gameState, setGameState, nextStage, dispatch, endGame, isPlayTEA]);
};
