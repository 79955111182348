import React from "react";
import { BarsOutlined } from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { List, Typography } from "antd";
import "./styles.less";

const DragHandle = SortableHandle(() => (
  <BarsOutlined style={{ cursor: "pointer" }} />
));

const SortableItem = SortableElement(({ stage, selected, setSelectedStage }) => (
  <List.Item
    onClick={() => {
      setSelectedStage(stage);
    }}
    className={`drag-and-drop-list-item ${selected ? "selected" : ""}`}
  >
    <DragHandle />
    <Typography.Text>{stage?.name}</Typography.Text>
  </List.Item>
));

export const SorteableMenuStage = SortableContainer(
  ({ stages, selectedStage, setSelectedStage }) => {
    return (
      <List id="drag-and-drop-list" key="sub">
        {stages
          ?.sort((a: any, b: any) => a.id - b.id)
          .map((item, index) => (
            <SortableItem
              index={index}
              selected={selectedStage?.id === item.id}
              setSelectedStage={setSelectedStage}
              stage={item}
            />
          ))}
      </List>
    );
  }
);
