import { Row } from "antd";
import React from "react";

export const RequiredField = ({ children }) => {
  return (
    <Row>
      {children}
      <span style={{ color: "red", marginLeft: "2px" }}>*</span>
    </Row>
  );
};
