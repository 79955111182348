import React from "react";
import { Col, Card, Row } from "antd";
import "./styles.less";
import { STAGE_NAMES } from "utils/enums";

const mecanics = [
  {
    id: 1,
    image: "/pareamento.jpg",
    label: "Clicar, Arrastar e Soltar",
    name: STAGE_NAMES.PAIRING,
    description: "",
  },
  {
    id: 2,
    image: "/limpar.png",
    label: "Pintar ou Limpar",
    name: STAGE_NAMES.PAINTING,
    description: "",
  },
  {
    id: 3,
    image: "/em-breve.jpg",
    label: "Conectar Pontos",
    disabled: true,
    name: STAGE_NAMES.CONNECTING_POINTS,
    description: "",
  },
  {
    id: 4,
    image: "/em-breve.jpg",
    label: "Arremessar",
    name: STAGE_NAMES.THROW,
    disabled: true,
    description: "",
  },
];

export const Mecanics = ({ onClick }) => {
  return (
    <Row justify="space-between">
      {mecanics.map((mecanic) => (
        <Col key={mecanic.id} style={{ marginTop: 40, marginBottom: 35 }}>
          <Card
            className="card-mecanics"
            bordered={false}
            key={mecanic.id}
            hoverable={!mecanic.disabled}
            style={{
              width: 300,
              maxHeight: 200,
              textAlign: "center",
              marginBottom: "20px",
            }}
            cover={
              <img
                style={{ maxHeight: 200, minHeight: 200 }}
                alt="mecanic"
                src={mecanic.image}
              />
            }
            onClick={() => onClick(mecanic.name)}
          >
            <Card.Meta
              title={mecanic.label}
              description={mecanic.description}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
