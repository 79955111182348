import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  Upload,
} from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { useState } from "react";
import { GameContext } from "../../..";
import { getBase64 } from "../../../../../../components/forms/form-register/person-registry";
import { compressImage } from "../../utils/compress-image";
import { Shape } from "../../game/classes/Shape";
import { PAINEL_ACTIONS_SCRIPTS_NAME } from "utils/enums";
import { v4 } from "uuid";
import _ from "lodash";
import $ from "jquery";
import { playTEACreatorDimensions } from "../../game/classes/stages/RootStage";

interface Props {
  onFinish: any;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

let startX;

const defaultStartX = 50;

export const shuffleShapes = (shapes: Shape[], size: number): Shape[] => {
  const windowWidth = Number($("canvas").width());
  const calcInitialWidth =
    windowWidth !== playTEACreatorDimensions.width
      ? windowWidth * 0.06
      : defaultStartX;

  const windowHeight = Number($(window).height());

  startX = calcInitialWidth;

  return _.shuffle(shapes).map((shape, index) => {
    if (Number(index) % size === 0) {
      startX = calcInitialWidth;
    }

    shape.y =
      Number(index) / size < 1 ? windowHeight * 0.05 : windowHeight * 0.45;
    shape.x = startX;

    startX += windowWidth * 0.1798;

    return shape;
  });
};

export const MemoryActivity = ({ visible, setVisible }: Props) => {
  const { game } = useContext(GameContext);
  const { gameState } = game;
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async ({ images, shuffle, timeToShowShape }) => {
    let startAxis = {
      x: 0,
      y: 0,
    };

    const getScriptQuandoClicar = (shapeId) => {
      return [
        {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.ACTIVE_SHAPE,
          name: `Ativar Elemento - ${shapeId}`,
          value: shapeId,
          order: 1,
          id: v4(),
        },
        {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE,
          name: `Mostrar figura - ${shapeId}`,
          value: shapeId,
          order: 2,
          id: v4(),
        },
        {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.AWAITING_TIME,
          name: `Aguardar tempo - ${timeToShowShape}`,
          value: timeToShowShape,
          order: 3,
          id: v4(),
        },
        {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.HIDE_SHAPE,
          name: `Esconder figura - ${shapeId}`,
          value: shapeId,
          order: 4,
          id: v4(),
        },
        {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.INACTIVE_SHAPE,
          name: `Inativar elemento - ${shapeId}`,
          value: shapeId,
          order: 5,
          id: v4(),
        },
      ];
    };

    const getPayloadShape = ({ image64, quandoAcertar, id, matchId }) => {
      return {
        base64Image: image64,
        zIndex: 1,
        bordas: false,
        active: false,
        clickable: false,
        hidden: false,
        visible: true,
        opacity: 1,
        canShuffle: true,
        isMemoryShape: true,
        quandoClicar: getScriptQuandoClicar(id),
        quandoAcertar,
        backGroundColor: "rgba(255, 255, 255, 0.5)",
        velocity: 10,
        height: 210,
        width: 210,
        dificult: -1,
        x: startX,
        y: 0,
        id,
        matchId: [matchId],
        imageCompressed: false,
      };
    };

    const windowWidth = Number($("canvas").width());
    const calcInitialWidth =
      windowWidth !== playTEACreatorDimensions.width
        ? windowWidth * 0.6
        : defaultStartX;
    startX = calcInitialWidth;

    let shapeImageId = Number(gameState.currentStage.getLastIdShape() ?? 0) + 1;

    let copyImageId = shapeImageId + 1;

    const shapesToSave: Shape[] = [];
    for (const index in images) {
      const image = images[index];

      startAxis.x += 100;

      const quandoAcertar = [
        {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE,
          name: `Mostrar figura - ${copyImageId}`,
          value: copyImageId,
          order: 2,
          id: v4(),
        },
        {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE,
          name: `Mostrar figura - ${shapeImageId}`,
          value: shapeImageId,
          order: 3,
          id: v4(),
        },
      ];

      const shapeImage = new Shape(
        getPayloadShape({
          image64: image.image64,
          id: shapeImageId,
          matchId: copyImageId,
          quandoAcertar,
        })
      );

      const shapeImage2 = new Shape(
        getPayloadShape({
          image64: image.image64,
          id: copyImageId,
          matchId: shapeImageId,
          quandoAcertar,
        })
      );

      shapeImageId += 2;
      copyImageId += 2;

      shapesToSave.push(...[shapeImage, shapeImage2]);
    }

    gameState.currentStage.addShape(shuffleShapes(shapesToSave, images.length));

    gameState.currentStage.acertosDoCenario += images.length;
    gameState.currentStage.isMemoryGame = true;
    gameState.currentStage.shuffleMemoryGame = shuffle;
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      title="Crie seu jogo da memória"
      onCancel={() => setVisible(false)}
    >
      <Formik
        initialValues={{
          images: [] as any[],
          shuffle: false,
          timeToShowShape: 3,
        }}
        validationSchema={""}
        onSubmit={async (data) => {
          try {
            setLoading(true);
            await onSubmit(data);

            Modal.warn({
              okCancel: false,
              okText: "Entendi",
              title: "Atenção",
              onOk: () => setVisible(false),
              content: (
                <>
                  <Typography.Paragraph>
                    As configurações dos elementos do jogo da memória foram
                    carregadas com sucesso.
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Caso essas configurações forem modificadas a fase pode não
                    funcionar corretamente.
                  </Typography.Paragraph>
                </>
              ),
            });
          } finally {
            setLoading(false);
          }
        }}
      >
        {({ handleSubmit, touched, errors, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Form.Item
              hasFeedback
              validateStatus={
                touched.timeToShowShape && errors.timeToShowShape
                  ? "error"
                  : undefined
              }
              help={touched.timeToShowShape && errors.timeToShowShape}
            >
              <div className="form-label">
                Tempo de pareamento (em segundos)
              </div>
              <Input
                type="number"
                name="timeToShowShape"
                style={{ width: "50%" }}
                value={values.timeToShowShape}
                onChange={(event) =>
                  setFieldValue("timeToShowShape", Number(event.target.value))
                }
                tabIndex={2}
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              validateStatus={
                touched.shuffle && errors.shuffle ? "error" : undefined
              }
              help={touched.shuffle && errors.shuffle}
            >
              <div className="form-label">Embaralhar ao começar jogo?</div>
              <Checkbox
                className="form-input"
                name="shuffle"
                checked={values.shuffle}
                onChange={(event) =>
                  setFieldValue("shuffle", event.target.checked)
                }
                tabIndex={2}
              />
            </Form.Item>
            <div>
              <div className="form-label">Imagens (5 Máx.)</div>
              <Upload
                multiple
                name={"image"}
                listType={"picture"}
                accept={"image/*"}
                customRequest={() => true}
                fileList={values.images?.map((image) => ({
                  status: "done",
                  name: "Foto",
                  uid: image.uid,
                  size: 0,
                  type: "",
                  thumbUrl: image.image64,
                }))}
                onChange={async ({ file, fileList }) => {
                  if (file.status === "removed") {
                    setFieldValue(
                      "images",
                      values.images.filter((image) => image.uid !== file.uid)
                    );
                    return null;
                  }

                  if (fileList.length <= 5) {
                    const filesToSave: any[] = [];

                    for (const fileToSave of fileList) {
                      fileToSave.status = "done";
                      const imageComp = await compressImage(
                        fileToSave.originFileObj
                      );
                      const imageBase64 = await getBase64(imageComp);
                      filesToSave.push({
                        image64: imageBase64,
                        uid: fileToSave.uid,
                      });
                    }

                    setFieldValue("images", filesToSave);
                  }
                }}
              >
                <Button>
                  <UploadOutlined /> Buscar
                </Button>
              </Upload>
            </div>

            {/* <Row>QuandoIniciar</Row>

            <Row>QuandoAcertar</Row>

            <Row>QuandoFinalizar</Row> */}

            <Row justify="center">
              <Button
                type="primary"
                size="large"
                className="common--secondary-submit-button"
                style={{ marginRight: 12 }}
                onClick={() => setVisible(false)}
                tabIndex={3}
              >
                Cancelar
              </Button>
              <Button
                disabled={!values.images.length}
                loading={loading}
                htmlType="submit"
                type="primary"
                size="large"
                className="common--submit-button"
                tabIndex={3}
              >
                Criar
              </Button>
            </Row>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
