import React, { useState } from "react";
import { Steps } from "intro.js-react";

export const IntroJS = ({ stepsEnabled, onExit, steps }) => {
  const [initialState] = useState({
    options: {
      skipLabel: "Sair",
      nextLabel: "Próximo",
      prevLabel: "Voltar",
      doneLabel: "Sair",
      showProgress: true,
      keyboardNavigation: true,
    },
    steps,
  });

  return (
    <Steps
      enabled={stepsEnabled}
      steps={initialState.steps}
      initialStep={0}
      options={initialState.options}
      onExit={onExit}
    />
  );
};
