let GifReader = require("omggif").GifReader;

export class Gif {
  _animator: Animator;
  _canvas: HTMLElement;

  constructor(buffer: ArrayBuffer, canvas: any) {
    let reader = new GifReader(new Uint8Array(buffer));
    this._canvas = canvas;

    canvas.width = reader.width;
    canvas.height = reader.height;

    this._animator = new Animator(
      reader,
      new Decoder().decodeFrame(reader, canvas)
    );
  }

  frames(canvasSelector: HTMLElement, onDrawFrame: (ctx, frame) => void) {
    this._animator.onDrawFrame = onDrawFrame;
    return this._animator.animateInCanvas(canvasSelector);
  }

  clearFrames() {
    return this._animator.stop();
  }

  get(): Animator {
    return this._animator;
  }
}

class Decoder {
  decodeFrame(gifReader, canvas) {
    const frames: any[] = [];

    // the width and height of the complete gif
    const { width, height } = gifReader;

    // This is the primary canvas that the tempCanvas below renders on top of. The
    // reason for this is that each frame stored internally inside the GIF is a
    // "diff" from the previous frame. To resolve frame 4, we must first resolve
    // frames 1, 2, 3, and then render frame 4 on top. This canvas accumulates the
    // previous frames.
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    if (!ctx) return [];

    for (let frameIndex = 0; frameIndex < gifReader.numFrames(); frameIndex++) {
      // the width, height, x, and y of the "dirty" pixels that should be redrawn
      const {
        width: dirtyWidth,
        height: dirtyHeight,
        x: dirtyX,
        y: dirtyY,
        delay,
      } = gifReader.frameInfo(0);

      // create hidden temporary canvas that exists only to render the "diff"
      // between the previous frame and the current frame
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = width;
      tempCanvas.height = height;
      const tempCtx = tempCanvas.getContext("2d");

      // extract GIF frame data to tempCanvas
      const newImageData = tempCtx?.createImageData(width, height);
      gifReader.decodeAndBlitFrameRGBA(frameIndex, newImageData?.data);
      tempCtx?.putImageData(
        newImageData as ImageData,
        0,
        0,
        dirtyX,
        dirtyY,
        dirtyWidth,
        dirtyHeight
      );

      // draw the tempCanvas on top. ctx.putImageData(tempCtx.getImageData(...))
      // is too primitive here, since the pixels would be *replaced* by incoming
      // RGBA values instead of layered.
      ctx.drawImage(tempCanvas, 0, 0);

      frames.push({
        delay: delay * 1.2,
        width: dirtyWidth,
        height: dirtyHeight,
        x: dirtyX,
        y: dirtyY,
        imageData: tempCanvas,
      });
    }

    return frames;
  }
}

class Animator {
  _reader;
  _frames;
  _onFrame;
  _loopCount: number;
  _loops: number;
  _frameIndex: number;
  _running: boolean;
  _lastTime = 0;
  _delayCompensation = 0;
  width: number;
  height: number;
  onDrawFrame?: (ctx, frames) => void;
  onFrame?: (frame: any) => void;
  canvas?: HTMLElement;

  constructor(reader, frames) {
    this._reader = reader;
    this._frames = frames;
    this.width = this._reader.width;
    this.height = this._reader.height;
    this._loopCount = this._reader.loopCount();
    this._loops = 0;
    this._frameIndex = 0;
    this._running = false;
  }

  private start() {
    this._lastTime = new Date().valueOf();
    this._delayCompensation = 0;
    this._running = true;
    this._nextFrameRender();
  }

  public stop() {
    this._running = false;
    this.canvas?.remove();
    return this;
  }

  private reset() {
    this._frameIndex = 0;
    this._loops = 0;
    return this;
  }

  private _nextFrameRender() {
    if (!this._running) {
      return;
    }

    this.onFrame?.(this._frames[this._frameIndex]);

    return this._enqueueNextFrame();
  }

  public _advanceFrame() {
    this._frameIndex += 1;
    if (this._frameIndex >= this._frames.length) {
      if (this._loopCount !== 0 && this._loopCount === this._loops) {
        return this.stop();
      }

      return this.reset();
    }
  }

  async _enqueueNextFrame() {
    let actualDelay, delta, frame, frameDelay;
    this._advanceFrame();

    while (this._running) {
      frame = this._frames[this._frameIndex];
      delta = new Date().valueOf() - this._lastTime;
      this._lastTime += delta;
      this._delayCompensation += delta;
      frameDelay = frame.delay * 10;
      actualDelay = frameDelay - this._delayCompensation;
      this._delayCompensation -= frameDelay;

      if (actualDelay < 0) {
        this._advanceFrame();
        continue;
      }

      setTimeout(this._nextFrameRender.bind(this), actualDelay);
      break;
    }
  }

  public animateInCanvas(canvas) {
    let ctx = canvas.getContext("2d");

    this.canvas = canvas;

    if (!this.onFrame) {
      this.onFrame = (frame) => {
        return this.onDrawFrame?.(ctx, frame);
      };
    }

    this.start();
    return this;
  }
}
