export enum CONTEXTS {
  PLAYTEA = "/playtea",
  PLAYTEA_CREATOR = "/playtea-creator",
}

export enum PAINEL_ACTIONS_SCRIPTS {
  NEW_AUDIO = 1,
  PREVIOUS_STAGE = 2,
  NEXT_STAGE = 3,
  SPECIFY_STAGE = 4,
  END_GAME = 5,
  SHOW_SHAPE = 6,
  HIDE_SHAPE = 7,
  AWAITING_TIME = 8,
  DESTROY_SHAPE = 9,
  SHOW_SHAPE_STAGE = 10,
  ACTIVE_SHAPE = 11,
  INACTIVE_SHAPE = 12,
  NEW_IMAGE = 13
}

export enum PAINEL_ACTIONS_SCRIPTS_NAME {
  NEW_AUDIO = "sound",
  PREVIOUS_STAGE = "previous",
  NEW_IMAGE = "image",
  NEXT_STAGE = "next",
  SPECIFY_STAGE = "specify",
  END_GAME = "end",
  SHOW_SHAPE = "show_shape",
  HIDE_SHAPE = "hide_shape",
  SHOW_SHAPE_STAGE = "show_shape_stage",
  DESTROY_SHAPE = "destroy_shape",
  INACTIVE_SHAPE = "inactive_shape",
  ACTIVE_SHAPE = "active_shape",
  AWAITING_TIME = "sleep",
}

export enum CORNERS_SHAPE_NAME {
  LEFT_TOP_CORNER = 0,
  TOP_MIDDLE_CORNER = 1,
  RIGHT_TOP_CORNER = 2,
  LEFT_MIDDLE_CORNER = 3,
  RIGHT_MIDDLE_CORNER = 4,
  LEFT_BOTTOM_CORNER = 5,
  BOTTOM_MIDDLE_CORNER = 6,
  RIGHT_BOTTOM_CORNER = 7,
}

export enum SCRIPT_NAMES {
  QUANDO_INICIAR = "quandoIniciar",
  QUANDO_TERMINAR = "quandoTerminar",
  QUANDO_ACERTAR = "quandoAcertar",
  QUANDO_ERRAR = "quandoErrar",
  QUANDO_CLICAR = "quandoClicar",
}

export enum STAGE_NAMES {
  PAIRING = "pairing",
  PAINTING = "painting",
  THROW = "throw",
  CONNECTING_POINTS = "connecting_points",
}

export enum SHAPE_TYPES {
  RECTANGLE = "rectangle",
}

export enum CLEANUP_MECANICS_TYPE {
  PAINTING = "painting",
  DELETING = "deleting",
}
