import { SHAPE_TYPES } from "utils/enums";
import { Shape } from "./Shape";
import { getStageByMecanic } from "./stages/get-stage-by-mecanic";

export const startIntroFigura = () => {
  return "OK";
};

export const sleep = (timer) => {
  return new Promise((resolve, _) => {
    setTimeout(() => resolve(timer), timer);
  });
};

export const promiseSequentially = async (
  promises: any[],
  data = [],
  countPromises = 0
) => {
  if (promises?.length > 0) {
    const result = await promises[countPromises]();
    countPromises++;
    if (countPromises < promises.length) {
      await promiseSequentially(promises, data.concat(result), countPromises);
    } else {
      return data;
    }
  }
};

export const LIMIT_SHAPES = 1000000;

export const defaultValues = {
  backGroundColor: "#FFFFFF",
  backGroundColorImage: "#000000",
  text: { value: "", tam: "40", fill: "#000000" },
  type: SHAPE_TYPES.RECTANGLE,
};

export const getNewShapeSize = (width) => {
  if (width < 300) return { width: 30, height: 30 };
  if (width < 500) return { width: 50, height: 50 };
  if (width < 700) return { width: 80, height: 80 };

  return { width: 100, height: 100 };
};

export const hexToRgb = (colorToCheck) => {
  const r = parseInt(colorToCheck.slice(1, 3), 16);
  const g = parseInt(colorToCheck.slice(3, 5), 16);
  const b = parseInt(colorToCheck.slice(5, 7), 16);
  return {
    r,
    g,
    b,
    a: 1,
    total: colorToCheck !== defaultValues.backGroundColor ? r + g + b : 0,
  };
};



export const cloneStage = (
  clone: any,
  game: any,
  id: any,
  textInput: any,
  nameScene: any,
) => {
  nameScene = textInput.val()
    ? textInput.val()
    : (clone.name ? clone.name : "Fase Principal") + " " + id;
  let stage = getStageByMecanic(clone.mecanic, {
    canvasDoc: {},
    id,
    quandoIniciar: clone.quandoIniciar,
    mecanic: clone.mecanic,
    colorPainting: clone.colorPainting,
    paintingType: clone.paintingType,
    isMemoryGame: clone.isMemoryGame,
    shuffleMemoryGame: clone.shuffleMemoryGame,
    quandoTerminar: clone.quandoTerminar,
    acertosDoCenario: clone.acertosDoCenario,
    isPlayTEA: false,
  });
  clone.shapes.forEach((shape: any) =>
    stage.shapes.push(new Shape({ ...shape }))
  );
  stage.setName(nameScene);
  game.currentStage.pauseScene();
  stage.start(false, game);
  game.setCurrentStage(stage);
  game.includeStage(stage);
  return { stage, nameScene };
};

export const loadAudio = (
  script,
  onloadaudio = false,
  preLoadTestAudio = false
) => {
  const startAudio = (audio) => {
    audio.currentTime = 0;
    audio.muted = false;
    audio.play();

    if (onloadaudio) {
      audio.muted = true;
    }
  };

  if (onloadaudio || preLoadTestAudio) {
    const audio = new Audio();

    audio.src = script.value;

    startAudio(audio);

    script.audio = audio;

    return preLoadTestAudio
      ? new Promise((resolve) => {
          script.audio.onended = () => {
            resolve(true);
          };
        })
      : script;
  } else if (script.audio) {
    startAudio(script.audio);

    return new Promise((resolve) => {
      script.audio.onended = () => {
        script.audio.currentTime = 0;
        script.audio.muted = true;
        resolve(true);
      };
    });
  }
};
