import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/configure-store";
import { App } from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/pt_BR";
import { PostMessagesTypes } from "./enums";
const { store, persistor } = configureStore();

const eventHandler = (message) => {
  if (message.data?.type === PostMessagesTypes.BackHandler) {
    return window.history.go(-1);
  }
};

window.addEventListener("message", eventHandler);

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.querySelector("#root")
);
