import React, { FC, ReactElement } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Login } from "pages/login";
import { PlayTEACreator } from "pages/playtea-creator";
import { Pupils } from "pages/playtea-creator/pupils";
import { Home } from "pages/home";
// import { SplashIntro } from 'pages/splashIntro'
import { PrivateRoute } from "./private-route";
import { CreatorStudentDetails } from "pages/playtea-creator/student-details";
import { CreateGame } from "pages/playtea-creator/create-game";
import { CreateGamePlayTEA } from "pages/playtea-creator/create-game/index-playtea";
import { StudentDetails } from "pages/playtea/student-details";
import { CreateScenes } from "pages/playtea-creator/scenes";
import { CONTEXTS } from "utils/enums";

export const Routes: FC = (): ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={[
            `${CONTEXTS.PLAYTEA_CREATOR}/entrar`,
            `${CONTEXTS.PLAYTEA}/entrar`,
          ]}
        >
          <Login />
        </Route>
        <PrivateRoute
          exact
          path={[
            `${CONTEXTS.PLAYTEA_CREATOR}/alunos/:id/jogos/:jogo`,
            `${CONTEXTS.PLAYTEA_CREATOR}/alunos/:id/jogos/newgame`,
            `${CONTEXTS.PLAYTEA_CREATOR}/cenarios/create`,
          ]}
        >
          <CreateGame />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${CONTEXTS.PLAYTEA_CREATOR}/alunos/:id/jogos`}
        >
          <CreatorStudentDetails />
        </PrivateRoute>
        <Route exact path={`${CONTEXTS.PLAYTEA}/run-test`}>
          <CreateGamePlayTEA />
        </Route>
        <PrivateRoute exact path={`${CONTEXTS.PLAYTEA}/run`}>
          <CreateGamePlayTEA />
        </PrivateRoute>
        <PrivateRoute path={`${CONTEXTS.PLAYTEA}`}>
          <StudentDetails />
        </PrivateRoute>
        <PrivateRoute path={`${CONTEXTS.PLAYTEA_CREATOR}/biblioteca-cenarios`}>
          <CreateScenes />
        </PrivateRoute>
        <PrivateRoute path={`${CONTEXTS.PLAYTEA_CREATOR}/alunos`}>
          <Pupils />
        </PrivateRoute>
        <PrivateRoute path={`${CONTEXTS.PLAYTEA_CREATOR}`}>
          <PlayTEACreator />
        </PrivateRoute>
        <Route path="/">
          <Home />
        </Route>

        {/* <Route path="/">
          <SplashIntro />
        </Route> */}
      </Switch>
    </BrowserRouter>
  );
};
