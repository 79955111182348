import React from "react";
import { Row, Spin } from "antd";

interface ILoader {
  text: string;
  spinning: boolean;
}

export const LoaderBlock = ({ text, spinning = false }: ILoader) =>
  spinning ? (
    <Row justify="center" style={{ padding: 80 }}>
      <div style={{ textAlign: "center" }}>
        <Spin size="large" style={{ marginBottom: 20 }} spinning={spinning} />

        <h4
          className="ant-typography empty-state--title"
          style={{ color: "#737373" }}
        >
          {text}
        </h4>
      </div>
    </Row>
  ) : (
    <> </>
  );
