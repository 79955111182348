import React, { CSSProperties, FC, ReactNode } from "react";
import { classNames } from "react-extras";
import "./styles.less";

interface Props {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const ContentBlock: FC<Props> = (props) => {
  const { children, className = "", style } = props;

  const container = classNames("container-block-content", className);

  return (
    <div className={container} style={style}>
      {children}
    </div>
  );
};
