import React, { useState } from "react";
// import { Form, Radio, Row } from 'antd'
import { PersonRegistry } from "./person-registry";
import { If } from "react-extras";

interface Props {
  onSubmit: any;
  buttonLabel: string;
  management?: boolean;
  validationSchema: any;
  initialValues: any;
}

export const RegisterForm = ({
  onSubmit,
  buttonLabel,
  management = false,
  validationSchema,
  initialValues,
}: Props) => {
  const [typeRegistry] = useState("person");
  return (
    <>
      {/* <If condition={management}>
        <Row
          align={'middle'}
          justify={'center'}
          style={{ display: 'grid', fontSize: '22px' }}>
          <Row>Selecione o tipo de cadastro</Row>
          <Row align={'middle'} justify={'center'}>
            <Form.Item hasFeedback>
              <Radio.Group
                onChange={({ target: { value } }) => setTypeRegistry(value)}>
                <Radio value="person"> Pessoa física </Radio>
                <Radio value="entity"> Instituição </Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
        </Row>
      </If> */}
      <If condition={typeRegistry === "person" || !management}>
        <PersonRegistry
          buttonLabel={buttonLabel}
          management={management}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </If>
    </>
  );
};
