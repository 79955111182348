import { Shape } from "../Shape";
import moment from "moment";
import { STAGE_NAMES } from "utils/enums";
import { RootStage } from "./RootStage";
import { CreateStageType } from "./create-stage.type";
import { hexToRgb, LIMIT_SHAPES } from "../utils";

let canvas;

let movingCorner = -1;
export class StagePaiting extends RootStage {
  public initialMovement: any;
  public executingScript = false;
  public dragging = false;
  public shapeSelected = -1;
  public shapeInUse: any;
  public dragoffx = 0;
  public init = true;
  public acertosExecutados;
  public dragoffy = 0;
  public clone: any;
  public stageLiveMode: any;
  public shapesDeleteds: Shape[] = [];
  public readyToStart = false;
  public colorPainting;
  public paintingType;
  public paintingAreaSize;

  constructor({
    id,
    canvasDoc,
    ids = [],
    shapes = [],
    quandoIniciar = [],
    quandoTerminar = [],
    acertosDoCenario = 0,
    paintingAreaSize = 0,
    isPlayTEA = false,
    mecanic = STAGE_NAMES.PAINTING,
    paintingType = "",
    colorPainting = "#ffffff",
  }: CreateStageType) {
    super({
      id,
      canvasDoc,
      ids,
      shapes,
      quandoIniciar,
      quandoTerminar,
      acertosDoCenario,
      isPlayTEA,
      mecanic,
    });
    this.clock = "";
    this.id = id;
    canvas = canvasDoc;
    this.context = canvas.getContext("2d");
    this.selectedShapes = [];
    this.shapes = [...shapes, this.selectedGroupShapes];
    this.ids = ids;
    this.name = "";
    this.initialMovement = null;
    this.isCurrentStage = false;
    this.executingScript = false;
    this.dragging = false;
    this.shapeSelected = -1;
    this.shapeInUse = null;
    this.dragoffx = 0;
    this.init = true;
    this.acertosExecutados = 0;
    this.acertosDoCenario = acertosDoCenario;
    this.dragoffy = 0;
    this.quandoIniciar = quandoIniciar;
    this.quandoTerminar = quandoTerminar;
    this.clone = null;
    this.stageLiveMode = null;
    this.shapesDeleteds = [];
    this.readyToStart = false;
    this.paintingType = paintingType;
    this.colorPainting = colorPainting;
    this.paintingAreaSize = paintingAreaSize;
    this.corners = {
      shapes: [],
      father: -1,
    };
    this.liveMode = false;
    this.actions = [];
    this.updateSizeCanvas(isPlayTEA);
  }

  clickAction(e) {
    this.clear(this.ghostctx);

    movingCorner = super.clickAction(e);

    return movingCorner;
  }

  addShape(shapes: Shape[] = []) {
    this.shapes = this.shapes.concat(shapes);
  }

  acceptMovingPaiting() {
    return 15000;
  }

  async start(force = false, gameState, liveMode?: boolean) {
    if (!this.looping || force) {
      this.isCurrentStage = true;

      this.clock = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
      this.draw();

      if (!liveMode) {
        this.looping = setInterval(() => {
          this.draw();
        }, 1000 / this.fps);
      }
    }
  }

  private withoutBlankCheck(percentagePaiting: number) {
    const helper = {
      0.1: 0.25,
      0.25: 0.5,
      0.5: 0.75,
    };

    return helper[percentagePaiting] ?? 1;
  }

  dispatchWhenHit({ shape, gameState, imageData }) {
    shape.painted = true;
    this.acertosExecutados += 1;
    let quandoAcertar = Object.keys(shape.quandoAcertar);

    if (quandoAcertar.length) {
      this.playScript({
        type: quandoAcertar,
        name: "quandoAcertar",
        script: shape.quandoAcertar,
        shapeSelected: Number(shape.id),
        gameState,
      });
    }

    for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] = 255;
      imageData.data[i + 1] = 255;
      imageData.data[i + 2] = 255;
    }

    this.context.putImageData(imageData, shape.x, shape.y);

    this.shapeInUse = null;
    if (this.acertosExecutados >= Number(this.acertosDoCenario)) {
      this.acertosExecutados = 0;
      this.startMovement = false;
      this.init = false;
      this.playScriptTerminar(null, gameState);
    }
  }

  checkHit({ shape }) {
    const imageData = this.context.getImageData(
      shape.x,
      shape.y,
      shape.width,
      shape.height
    );
    const hexCalc = hexToRgb(this.colorPainting);

    if (!shape.imageData) {
      for (let i = 0; i < imageData.data.length; i += 4) {
        if (
          imageData.data[i] === 255 &&
          imageData.data[i + 1] === 255 &&
          imageData.data[i + 2] === 255
        ) {
          imageData.data[i + 3] = 1;
          shape.imageIgnoreBlanks++;
        }
      }

      shape.updateTempImageData(imageData);
    } else {
      for (let i = 0; i < imageData.data.length; i += 4) {
        if (
          imageData.data[i] === hexCalc.r &&
          imageData.data[i + 1] === hexCalc.g &&
          imageData.data[i + 2] === hexCalc.b &&
          shape.imageData.data[i + 3] !== 1
        ) {
          shape.filledPixels += 1;
        }
      }
    }

    const minor = shape.imageIgnoreBlanks * shape.percentagePaiting;
    const percentagePaitingToCheck = minor
      ? shape.percentagePaiting
      : this.withoutBlankCheck(shape.percentagePaiting);
    let requiredArea =
      percentagePaitingToCheck * (shape.width * shape.height - minor);

    return { requiredArea, imageData };
  }

  checkPaiting(gameState) {
    const filteredShapes = this.shapes
      .sort((a, b) => b.zIndex - a.zIndex)
      .filter((shape) => shape.id !== LIMIT_SHAPES);
    for (const shape of filteredShapes) {
      if (shape) {
        shape.filledPixels = 0
        const { requiredArea, imageData } = this.checkHit({
          shape,
        });


        if (!shape.painted && shape.filledPixels >= requiredArea) {
          this.dispatchWhenHit({ shape, gameState, imageData });

          break;
        }
      }
    }
  }

  getAreaSize() {
    return (this.paintingAreaSize + 1) * 10;
  }

  moveAction(e, gameState) {
    if (!this.liveMode) {
      super.moveAction(e, gameState);
    } else {
      //  logica de pintar e limpar
      const mouse = this.mouse.getPosition(e, canvas);

      const ctx = this.context;

      this.shapeInUse = null;

      const shapesSorted = this.shapes.sort((a, b) => b.zIndex - a.zIndex);
      for (let i = 0; i < shapesSorted.length; i++) {
        if (this.shapes[i].contains(mouse.x, mouse.y)) {
          this.shapeInUse = this.shapes[i];
          break;
        }
      }

      if (
        this.startMovement &&
        this.colorPainting &&
        this.shapeInUse &&
        this.shapeInUse.active &&
        this.shapeInUse.canPaint &&
        !this.shapeInUse.painted
      ) {
        ctx.moveTo(mouse.x, mouse.y);
        ctx.beginPath();
        ctx.arc(mouse.x, mouse.y, this.getAreaSize(), 0, Math.PI * 2, false);
        ctx.closePath();
        const rgb = hexToRgb(this.colorPainting);
        ctx.fillStyle = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
        ctx.fill();
      }

      this.checkPaiting(gameState);
    }
  }

  setLiveMode(flag, gameState) {
    this.liveMode = flag;
    if (this.liveMode) {
      super.setLiveMode(flag, gameState);
      this.draw();
      clearInterval(this.looping);
    } else {
      this.clear(this.context);
      this.looping = setInterval(() => {
        this.context.globalCompositeOperation = "source-over";
        this.draw();
      }, 1000 / this.fps);
    }
  }

  async dropAction(_, gameState) {
    this.startMovement = false;
    if (!this.liveMode) {
      this.oldY = 0;
      this.oldX = 0;
      this.startMovement = null;

      super.dropAction(_, gameState);

      this.dragging = false;
    }
  }
}
