import { useCallback, useMemo } from "react";
import { END_GAME, END_GAME_PLAY_TEA } from "redux/games/actions";
import { useDispatch } from "react-redux";
import {
  loadAudio,
  sleep,
} from "../pages/playtea-creator/create-game/canvas/game/classes/utils";
import { PAINEL_ACTIONS_SCRIPTS_NAME, SCRIPT_NAMES } from "utils/enums";

export const usePlayScriptShapes = ({
  endGame,
  gameState,
  isPlayTEA,
  nextStage,
  setGameState,
}) => {
  const dispatch = useDispatch();

  const runScriptByType = useCallback(
    async ({
      name,
      _startStage,
      startEndGame,
      currentStage,
      scriptsToRun,
      shapeSelected,
      callbackActive,
      callbackInactive,
    }) => {
      if (currentStage.liveMode) {
        let shapeToCheck =
          currentStage.shapes.find(
            (shape) => shape.id === scriptsToRun.value
          ) ?? {};

        const currentShape = currentStage.shapes.find(
          (shape) =>
            shape.id === (shapeSelected?.id ?? currentStage.shapeInUse?.id)
        );
        switch (scriptsToRun.type) {
          case PAINEL_ACTIONS_SCRIPTS_NAME.NEW_IMAGE:
            currentShape.hidden = false;
            currentShape.image64 = scriptsToRun.value;
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.NEW_AUDIO:
            const startAudio = () => loadAudio(scriptsToRun, false, !isPlayTEA);

            name === SCRIPT_NAMES.QUANDO_INICIAR
              ? await startAudio()
              : startAudio();

            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.END_GAME:
            const quandoTerminar = Object.keys(currentStage.quandoTerminar);
            currentStage.init = false;

            if (quandoTerminar.length) {
              await currentStage.playScriptTerminar(-1, gameState);
            }

            dispatch({
              type: startEndGame,
              payload: currentStage.clock,
            });
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.NEXT_STAGE:
            const nextIndex = gameState.stages.findIndex(
              (value) => value.id === currentStage.id
            );
            const nextStage = gameState.stages?.[nextIndex + 1];
            currentStage.init = false;
            if (nextStage) {
              let quandoTerminar = Object.keys(currentStage.quandoTerminar);
              if (quandoTerminar.length)
                await currentStage.playScriptTerminar(nextStage.id, gameState);
              else {
                await _startStage(nextStage);
              }
            } else {
              dispatch({
                type: startEndGame,
                payload: currentStage.clock,
              });
            }
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.PREVIOUS_STAGE:
            const prevIndex = gameState.stages.findIndex(
              (value) => value.id === currentStage.id
            );
            const prevStage = gameState.stages?.[prevIndex - 1];
            currentStage.init = false;
            if (prevStage) {
              let quandoTerminar = Object.keys(currentStage.quandoTerminar);
              if (quandoTerminar.length)
                await currentStage.playScriptTerminar(prevStage.id, gameState);
              else {
                currentStage.shapeSelected = -1;
                await _startStage(prevStage);
              }
            } else {
              dispatch({
                type: startEndGame,
                payload: currentStage.clock,
              });
            }
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.SPECIFY_STAGE:
            let specify = gameState?.stages.find(
              (stage) => stage.id === scriptsToRun.value
            );
            if (specify) {
              let quandoTerminar = Object.keys(currentStage.quandoTerminar);
              if (quandoTerminar.length)
                await currentStage.playScriptTerminar(
                  scriptsToRun.value,
                  gameState
                );
              else {
                await _startStage(specify);
              }
            }
            currentStage.init = false;
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE:
            if (scriptsToRun.value === "todos") {
              currentStage.shapes.forEach((shape) => {
                shape.hidden = false;
              });
            } else {
              shapeToCheck.hidden = false;
            }
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.HIDE_SHAPE:
            if (scriptsToRun.value === "todos") {
              currentStage.shapes.forEach((shape) => {
                shape.hidden = true;
                shape.keepBordersVisible = true;
              });
            } else if (currentStage.isMemoryGame) {
              shapeToCheck.hidden = !shapeToCheck.used;
            } else {
              shapeToCheck.hidden = true;
            }
            break;

          case PAINEL_ACTIONS_SCRIPTS_NAME.AWAITING_TIME:
            await sleep(scriptsToRun.value * 1000);
            break;

          case PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE_STAGE:
            shapeToCheck.hidden = false;
            break;

          case PAINEL_ACTIONS_SCRIPTS_NAME.DESTROY_SHAPE:
            if (!shapeToCheck.matchId?.length) {
              currentStage.shapes = currentStage.shapes.filter(
                (shape) => shapeToCheck.id !== shape.id
              );
            }
            break;

          case PAINEL_ACTIONS_SCRIPTS_NAME.INACTIVE_SHAPE:
            if (scriptsToRun.value === "todos") {
              currentStage.shapes.forEach((shape) => {
                shape.active = false;
              });
            } else if (shapeToCheck?.active) {
              if (currentStage.isMemoryGame) {
                shapeToCheck.active = shapeToCheck.used;
              } else if (!shapeToCheck.matchId?.length) {
                const interval = setInterval(() => {
                  if (
                    shapeToCheck.x === shapeToCheck.posInitialX &&
                    shapeToCheck.y === shapeToCheck.posInitialY
                  ) {
                    shapeToCheck.tempOpacity = shapeSelected.opacity;
                    shapeToCheck.opacity = 0.1;
                    shapeToCheck.hasShadow = false;
                    shapeToCheck.active = false;
                    shapeToCheck.clickable = false;
                    clearInterval(interval);
                  }
                }, 100);
              } else {
                shapeToCheck.active = false;
              }
            }

            callbackInactive?.();
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.ACTIVE_SHAPE:
            if (scriptsToRun.value === "todos") {
              currentStage.shapes.forEach((shape) => {
                shape.active = true;
              });
            } else {
              shapeToCheck.active = true;
              shapeToCheck.opacity =
                shapeToCheck.tempOpacity ?? shapeToCheck.opacity;
            }

            callbackActive?.();
            break;
          default:
            return;
        }

        if (shapeToCheck?.id) {
          shapeToCheck.draw(currentStage.getContext(), true);
        }
      }

      return sleep(200);
    },
    [dispatch, gameState, isPlayTEA]
  );

  useMemo(async () => {
    if (
      gameState.scriptInitiate &&
      gameState.executing !== gameState.scriptInitiate &&
      gameState.currentStage.liveMode
    ) {
      setGameState((state) => ({
        ...state,
        scriptInitiate: false,
        executing: gameState.scriptInitiate,
      }));
      const { currentStage } = gameState;
      const {
        name,
        script,
        callbackActive,
        callbackInactive,
        shapeSelected,
        init = true,
      } = gameState.scriptInitiate;

      const _startStage = async (stage) => {
        currentStage.stopRenderCanvas();
        currentStage.pauseScene();
        currentStage.acertosExecutados = 0;
        nextStage(stage)();
      };

      currentStage.init = init;

      let scriptsToRun = script.sort((a, b) => a.order - b.order);

      const startEndGame = endGame ? END_GAME_PLAY_TEA : END_GAME;

      const matrizScripts: any[][] = [];

      let scriptArray: any[] = [];

      for (let i = 0; i < scriptsToRun.length; i++) {
        if (
          scriptsToRun[i].type !== PAINEL_ACTIONS_SCRIPTS_NAME.AWAITING_TIME
        ) {
          scriptArray.push(scriptsToRun[i]);
        } else {
          matrizScripts.push(scriptArray);
          scriptArray = [scriptsToRun[i]];
        }
      }

      if (scriptArray.length) {
        matrizScripts.push(scriptArray);
      }

      for (const i in matrizScripts) {
        for (const matriz of matrizScripts[i]) {
          await runScriptByType({
            name,
            _startStage,
            startEndGame,
            callbackActive,
            callbackInactive,
            currentStage,
            scriptsToRun: matriz,
            shapeSelected,
          });
        }
      }

      currentStage.init = true;
      if (
        [
          SCRIPT_NAMES.QUANDO_CLICAR,
          SCRIPT_NAMES.QUANDO_ACERTAR,
          SCRIPT_NAMES.QUANDO_ERRAR,
        ].includes(name)
      ) {
        currentStage.shapeInUse = {};
      }

      setGameState((state) => ({
        ...state,
        executing: false,
      }));

      currentStage.readyToStart = true;
    }
  }, [gameState, nextStage, endGame, setGameState, runScriptByType]);
};
