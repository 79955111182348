import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useRef,
  ReactElement,
} from "react";
import {
  Modal,
  Input,
  Row,
  Typography,
  message,
  Upload,
  Col,
  Button,
} from "antd";
import { GameContext } from ".";
import { Shape } from "./canvas/game/classes/Shape";
import { If, Choose } from "react-extras";
import { IntroJS } from "./canvas/utils/tutorial";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import GameService from "../../../redux/games/games";
import { compressImage } from "./canvas/utils/compress-image";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import "./styles.less";
import {
  PuzzleActivity,
  LiteracyActivity,
} from "./canvas/components/activities";
import { getNewShapeSize } from "./canvas/game/classes/utils";
import { CanvasDesign } from "./canvas/create-design";
import { useActionsGameState } from "../../../hooks/useActionsGameState";
import { encrypt } from "utils/crypto";
import { PasswordExportModal } from "components/modals/password-export-modal";
import { dataSaveGame, dataSaveScene, introStepsInitialPage } from "./utils";
import { Shortcuts } from "./canvas/components/shortcuts";
import { ModalActivities } from "./canvas/components/activities/modal";
import { ModalStages } from "./canvas/components/modal-stages";
import { getStageByMecanic } from "./canvas/game/classes/stages/get-stage-by-mecanic";
import { STAGE_NAMES } from "utils/enums";
import { ModalMecanics } from "./canvas/components/modal-mecanics";
import { MemoryActivity } from "./canvas/components/activities/memory";

interface ISaveGameProps {
  textMessage?: string;
}

interface Props {
  isScene?: boolean;
  onClickCanvasOptions: any;
  onClickScenes: any;
}

export const getGameAsJson = (game, type = "import") => {
  return {
    stages: game.stages.map((stage) => {
      return {
        id: stage?.id,
        width: stage.width,
        height: stage.height,
        shapes: stage.shapes.map((shape) => ({ ...shape, states: undefined })),
        ids: stage.ids,
        name: stage.name,
        mecanic: stage.mecanic,
        isMemoryGame: stage.isMemoryGame,
        isPuzzleGame: stage.isPuzzleGame,
        paintingAreaSize: stage.paintingAreaSize,
        shuffleMemoryGame: stage.shuffleMemoryGame,
        paintingType: stage.paintingType,
        colorPainting: stage.colorPainting,
        acertosExecutados: 0,
        acertosDoCenario: stage.acertosDoCenario,
        quandoIniciar: stage.quandoIniciar,
        quandoTerminar: stage.quandoTerminar,
        sceneScripts: stage.sceneScripts,
      };
    }),
    name: game?.name || "Example",
    foto: game?.foto || "#",
    codigo: game?.codigo,
    type,
  };
};

export const exportGame = (game, password?: string) => {
  const gameJson = getGameAsJson(game);
  const gameEncrypted = encrypt(JSON.stringify(gameJson), password);

  const textFileAsBlob = gameEncrypted;
  if ("msSaveOrOpenBlob" in navigator) {
    (navigator as any).msSaveOrOpenBlob(textFileAsBlob, gameJson.name);
  } else {
    const downloadLink = document.createElement("a");
    downloadLink.href = "data:text/plain;charset=utf-8," + textFileAsBlob;
    downloadLink.download = gameJson.name.replace(/\.|,/g, "_");
    downloadLink.style.display = "none";
    downloadLink.click();
  }
};

export const Navigator = ({ isScene, onClickScenes }: Props) => {
  const { game } = useContext(GameContext);
  const history = useHistory();
  const { setGameState, gameState } = game;
  const { id: pupilCode, jogo }: any = useParams();
  const [defaultLabel] = useState(isScene ? "Cenário" : "Jogo");
  const [mecanicModal, setMecanicModal] = useState(false);
  const [jogoCode, setJogoCode] = useState(jogo);
  const [savingGame, setSavingGame] = useState(false);
  const [testingMode, setTestingMode] = useState(false);
  const [requestedEndGame, setRequestedEndGame] = useState(false);
  const [isSavingGame, setIsSavingGame] = useState(false);
  const [gameDesign, showGameDesign] = useState(false);
  const [modalActivities, setModalActivities] = useState(false);
  const [temporaryGame, setTemporalyGame]: any = useState({});
  const [closePage, setClosePage] = useState(false);
  const [imageGame, setImageGame]: any = useState("");
  const [modalPasswordExport, showModalPasswordExport] = useState();
  const [showStagesModal, setShowStagesModal] = useState(false);
  const stage = useMemo(() => gameState.currentStage, [gameState]);
  const { gameReducer, managementReducer, studentReducer }: any = useSelector(
    (state) => state
  );
  const { management } = managementReducer;
  const { student } = studentReducer;

  const renderElements = () => {
    const { width, height } = getNewShapeSize(stage.getWidth());
    const id = Number(stage.getLastIdShape() ?? 0) + 1;
    const rectX = Math.floor(Math.random() * (stage.getWidth() - height));
    const rectY = Math.floor(Math.random() * (stage.getHeight() - width));
    const newShape = new Shape({
      x: rectX,
      y: rectY,
      width,
      height,
      zIndex: 1,
      clickable: true,
      visible: true,
      velocity: 10,
      id,
      matchId: [],
    });
    gameState.currentStage.addShape([newShape]);
  };

  const [currentActivity, setCurrentActivity] = useState<ReactElement>();
  const [visibleActivity, setVisibleActivity] = useState<boolean>(false);
  const onFinishStepsActivity = () => {
    setVisibleActivity(false);
  };

  const activities = [
    {
      title: "Quebra-Cabeça",
      src: "/quebra-cabeça.png",
      onClick: (event) => {
        setCurrentActivity(
          <PuzzleActivity
            onFinish={onFinishStepsActivity}
            visible
            setVisible={() => setVisibleActivity(false)}
          />
        );
        setModalActivities(false);
      },
    },
    {
      title: "Jogo da memória",
      src: "/memoria.png",
      onClick: (event) => {
        setCurrentActivity(
          <MemoryActivity
            onFinish={onFinishStepsActivity}
            visible
            setVisible={() => setVisibleActivity(false)}
          />
        );
        setModalActivities(false);
      },
    },
    {
      title: "Alfabetização e Letramento",
      src: "/letras.jpg",
      onClick: (event) => {
        setCurrentActivity(
          <LiteracyActivity
            onFinish={onFinishStepsActivity}
            visible
            setVisible={() => setVisibleActivity(false)}
          />
        );
        setModalActivities(false);
      },
    },
  ];

  const createNewStage = (mecanic: STAGE_NAMES) => {
    game.pauseStages();
    const id: number = gameState.stages.sort((a, b) => b.id - a.id)[0]?.id || 0;
    const stage = getStageByMecanic(mecanic, {
      canvasDoc: document.querySelector("canvas"),
      id: id + 1,
      mecanic,
    });
    stage.setName(`Fase ${id}`);
    game.updateStages({ stages: [...gameState.stages, stage] });
    game.updateCurrentStage(stage);
    gameState.setEvents(stage);
    message.success("Fase adicionada com sucesso");
  };

  const liveMode = useCallback(async () => {
    if (gameState.currentStage?.id) {
      const temporaryGame_ = {
        stages: _.cloneDeep(gameState.stages),
        currentStage: gameState?.currentStage?.id || 1,
      };
      if (testingMode) {
        game.createFromPreview(temporaryGame, gameState);
        setTestingMode(false);
      } else {
        gameState.currentStage.shapeInUse = null;
        gameState.currentStage.setLiveMode(true, gameState);
        setTestingMode(true);
        setTemporalyGame(temporaryGame_);
      }
    }
  }, [game, gameState, temporaryGame, testingMode]);

  const changeStage = (key) => {
    game.pauseStages();
    const stage = gameState.stages.find((stage) => stage.id === Number(key));
    gameState.currentStage.shapeInUse = null;
    gameState.currentStage.corners = {
      shapes: [],
    };
    game.updateCurrentStage(stage, gameState.stages);
    gameState.setEvents(stage);
    stage.resizeResolution();
    stage.start(true, gameState);
  };
  const [stepsEnabled, setEnabledSteps] = useState(false);
  const stepsRef = useRef(false);

  useEffect(() => {
    if (!stepsRef.current) {
      stepsRef.current = true;
    }
  }, [stepsRef]);

  const convertGameInHistoryState = (game) => {
    const gameAsJson = getGameAsJson(game);
    return {
      game: {
        nome: gameAsJson.name,
        game: JSON.stringify(gameAsJson),
      },
    };
  };

  const saveImage = () => {
    const imageName = prompt("Qual o nome da imagem?");
    const canvas: any = document.getElementById("canvas-design");
    const canvasDataURL = canvas?.toDataURL();
    const a = document.createElement("a");
    a.href = canvasDataURL;
    a.download = imageName || "desenho";
    a.click();
  };

  const imageToSave =
    imageGame || gameState.image || gameState.currentStage?.canvas?.toDataURL();

  const saveGame = async ({
    textMessage = `${defaultLabel} atualizado com sucesso`,
  }: ISaveGameProps) => {
    const data = getGameAsJson(gameState, "save");
    const { formData }: any = isScene
      ? dataSaveScene({
          data,
          tutorCode: management?.codigo,
          imageScene: imageToSave,
        })
      : dataSaveGame({
          data,
          pupilCode,
          imageGame: imageToSave,
        });
    setIsSavingGame(true);
    try {
      let result;
      if (isScene) {
        ({
          data: { result },
        } = await GameService.createScene(formData));
      } else {
        ({
          data: { result },
        } = await GameService.create(
          formData,
          jogoCode,
          student.codigo ?? pupilCode
        ));
      }
      if (result.insertId > 0) {
        setJogoCode(result.insertId);
        setGameState({ ...gameState, codigo: result.insertId });
      }
      history.replace({
        pathname: history.location.pathname,
        state: { ...convertGameInHistoryState(gameState), scene: isScene },
      });
      message.success(textMessage);
    } catch {
      message.error(
        `Não foi possivel atualizar o ${defaultLabel}, tente novamente`
      );
    }

    setIsSavingGame(false);
  };

  useActionsGameState({
    defaultLabel,
    gameReducer,
    gameState,
    liveMode,
    requestedEndGame,
    setRequestedEndGame,
  });

  return (
    <div
      className="page-wrapper chiller-theme toggled"
      style={{ height: "98%", width: "100%" }}
    >
      <IntroJS
        steps={introStepsInitialPage}
        stepsEnabled={stepsEnabled}
        onExit={() => setEnabledSteps(false)}
      />
      <span id="show-sidebar" className="btn btn-sm btn-dark">
        <i className="fa fa-bars" />
      </span>

      <Modal
        title="Tem certeza que deseja sair?"
        okText="Salvar rascunho e sair"
        visible={closePage}
        cancelText="Sair"
        footer={
          <>
            <Button
              className="common--secondary-submit-button"
              onClick={async () => {
                if (isScene) return history.go(-1);
                history.push("../jogos");
              }}
            >
              Sair
            </Button>
            <Button
              type="primary"
              className="common--submit-button"
              onClick={async () => {
                try {
                  await saveGame({ textMessage: "Rascunho salvo com sucesso" });
                  if (isScene) {
                    return history.go(-1);
                  }
                  history.push("../jogos");
                } catch {
                  message.error(
                    "Não foi possivel salvar seu rascunho, tente novamente."
                  );
                }
              }}
            >
              Salvar rascunho e sair
            </Button>
          </>
        }
        onCancel={() => setClosePage(false)}
      >
        <Typography.Text>
          Você pode salvar o progresso clicando sem (salvar rascunho e sair).
        </Typography.Text>
      </Modal>
      <Modal
        visible={savingGame}
        okText={`Salvar ${defaultLabel}`}
        title={`Salvar ${defaultLabel}`}
        okButtonProps={{
          loading: isSavingGame,
        }}
        cancelText="Voltar"
        onCancel={() => setSavingGame(false)}
        onOk={async () => saveGame({})}
      >
        <Row align="middle" justify="center">
          <div className="form-label">Nome</div>
          <Input
            value={gameState?.name}
            size="large"
            type="text"
            placeholder={`Digite o nome do ${defaultLabel}`}
            title={`Digite um nome para o ${defaultLabel}`}
            onChange={({ target }) =>
              setGameState({ ...gameState, name: target.value })
            }
          />
        </Row>
        <Row align="middle" justify="center" style={{ marginTop: 15 }}>
          <Col>
            <Upload
              listType="picture-card"
              showUploadList={false}
              customRequest={() => ""}
              accept="image/*"
              onChange={async (info) => {
                try {
                  const image = await compressImage(info.file.originFileObj);
                  const oReader = new FileReader();
                  oReader.addEventListener("load", (event) =>
                    setImageGame(event.target?.result)
                  );
                  oReader.readAsDataURL(image);
                } catch {
                  message.error(
                    `Ocorreu um erro ai importar ${info.file.name}, tente novamente`
                  );
                }
              }}
            >
              <div className="image-edit">
                <PlusOutlined />
                <div className="ant-upload-text">Imagem</div>
              </div>
            </Upload>
          </Col>
          <If condition={Boolean(imageToSave)}>
            <img src={imageToSave} alt="capa-jogo" width="90" height="90" />
          </If>
        </Row>
      </Modal>
      <nav id="sidebar" className="sidebar-wrapper navigator">
        <div className="sidebar-content">
          <div className="sidebar-header">
            {!isScene && (
              <>
                <div className="user-pic">
                  <img
                    src={student.foto}
                    style={{
                      maxWidth: "60px",
                      maxHeight: "56px",
                    }}
                    className="img-responsive img-rounded photo-aluno"
                    alt="User"
                  />
                </div>
                <div className="user-info" style={{ color: "#818896" }}>
                  <span className="user-name">
                    <strong>
                      {student.nome} <Shortcuts />{" "}
                    </strong>
                  </span>
                  <span className="user-role">Tutor: {management.nome}</span>

                  <span
                    className="tutorial"
                    id="step12"
                    onClick={() => setEnabledSteps(true)}
                  >
                    Tutorial
                  </span>
                </div>
              </>
            )}
            <ul style={{ display: "flex", overflowX: "scroll" }}>
              <If condition={!testingMode && !isScene}>
                <Row justify="center" align="middle" style={{ marginLeft: 20 }}>
                  <Button
                    type="primary"
                    onClick={() => setShowStagesModal(true)}
                  >
                    Fases
                  </Button>
                </Row>
              </If>
              <If condition={!testingMode}>
                <li id="step1">
                  <div className="action" onClick={() => setSavingGame(true)}>
                    <i className="fa fa-save" />
                    <span className="steps">Salvar {defaultLabel}</span>
                  </div>
                </li>
                <li id="step2">
                  <div
                    className="export-game action"
                    onClick={() =>
                      showModalPasswordExport({
                        ...gameState,
                        foto: imageToSave,
                      })
                    }
                  >
                    <i className="fa fa-download" />
                    <span className="steps">Exportar {defaultLabel}</span>
                  </div>
                </li>
                <li id="step3" onClick={renderElements}>
                  <div className="newBox action">
                    <i className="fa fa-plus" />
                    <span className="steps">Novo elemento</span>
                  </div>
                </li>
                <If
                  condition={
                    gameState.currentStage.mecanic === STAGE_NAMES.PAIRING
                  }
                >
                  <li id="step3" onClick={() => setModalActivities(true)}>
                    <div className="newBox action">
                      <i className="fa fa-plus" />
                      <span className="steps">Nova atividade</span>
                    </div>
                  </li>
                </If>
              </If>
              <li
                id="step6"
                onClick={() => {
                  if (!gameState.endScriptTerminated) {
                    liveMode();
                  } else {
                    message.info("Estamos finalizando o jogo...");
                    setRequestedEndGame(true);
                  }
                }}
              >
                <div className="action">
                  <i className="fa fa-gamepad" />
                  <span className="steps">
                    <Choose>
                      <Choose.When condition={testingMode}>
                        Sair do modo teste{" "}
                        {requestedEndGame && <LoadingOutlined />}
                      </Choose.When>
                      <Choose.Otherwise>Modo teste</Choose.Otherwise>
                    </Choose>
                  </span>
                </div>
              </li>

              {!isScene && (
                <li id="step9" onClick={() => setMecanicModal(true)}>
                  <div className="action">
                    <i className="fa fa-legal" />
                    <span className="steps">Nova fase</span>
                  </div>
                </li>
              )}

              <li id="step10" onClick={() => onClickScenes()}>
                <div className="action">
                  <i className="fa fa-plug" />
                  <span className="steps">Cenários</span>
                </div>
              </li>

              <li id="step11" onClick={() => showGameDesign(true)}>
                <div className="action">
                  <i className="fa fa-building" />
                  <span className="steps">Crie seu desenho!</span>
                </div>
              </li>

              <li id="step7">
                <div
                  className="action"
                  onClick={async () => setClosePage(true)}
                >
                  <i className="fa fa-hand-o-left" />
                  <span className="steps">Sair da página</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <ModalActivities
        activities={activities}
        modalActivities={modalActivities}
        setModalActivities={setModalActivities}
        setVisibleActivity={setVisibleActivity}
      />
      <ModalStages
        visible={showStagesModal}
        stages={gameState?.stages}
        game={game}
        changeStage={changeStage}
        setVisible={setShowStagesModal}
      />
      <ModalMecanics
        visible={mecanicModal}
        action={(mecanic) => {
          createNewStage(mecanic);
        }}
        setVisible={setMecanicModal}
      />
      {visibleActivity && currentActivity}
      <If condition={gameDesign}>
        <Modal
          width={1200}
          okText="Criar desenho"
          cancelButtonProps={{
            className: "common--secondary-submit-button",
          }}
          onOk={() => saveImage()}
          bodyStyle={{ height: 600 }}
          visible={gameDesign}
          onCancel={() => showGameDesign(false)}
        >
          <CanvasDesign />
        </Modal>
      </If>

      <PasswordExportModal
        gameState={modalPasswordExport}
        studentCode={student?.codigo}
        setVisible={showModalPasswordExport}
        visible={Boolean(modalPasswordExport)}
      />
    </div>
  );
};
